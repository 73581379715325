export default function SamplingTypeBadge({
  sampling_type,
}: {
  sampling_type: string | string[];
}) {
  const types =
    typeof sampling_type === 'string' ? [sampling_type] : sampling_type;

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'vaginal':
        return 'bg-pink-600';
      case 'stool':
        return 'bg-amber-950';
      case 'stool_chemistry':
        return 'bg-blue-900';
      default:
        return 'bg-gray-700';
    }
  };

  return (
    <>
      {types.map((type: string, idx: number) => (
        <span
          key={idx}
          className={`${getBackgroundColor(
            type,
          )} text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded`}
        >
          {type}
        </span>
      ))}
    </>
  );
}
