import React, { useState } from 'react';

import './styles.css';

export default function TextInput({
  type,
  title,
  subtitle,
  placeholder,
  value,
  maxLength,
  disabled = false,
  onChange,
  className,
  register,
  isDirty = false,
}: {
  type?: 'text' | 'password' | 'email' | 'number' | 'textarea';
  title: string;
  subtitle?: string;
  placeholder?: string;
  value: string | number;
  disabled?: boolean;
  maxLength?: number;
  register?: any;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  className?: string;
  isDirty?: boolean;
}) {
  return (
    <div className='flex-1 max-w-full mt-4'>
      <div className='flex flex-col relative'>
        <label className={`${subtitle ? '' : 'mb-1'} p-0 text-sm`}>
          {title}
        </label>
        {subtitle && (
          <label className='mb-1 p-0 text-xs opacity-60'>{subtitle}</label>
        )}
        {type === 'textarea' ? (
          register ? (
            <textarea
              {...register}
              placeholder={placeholder}
              value={value ?? ''}
              maxLength={maxLength}
              disabled={disabled}
              className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className} ${
                isDirty ? 'dirty' : ''
              }`}
            />
          ) : (
            <textarea
              placeholder={placeholder}
              value={value ?? ''}
              maxLength={maxLength}
              onChange={onChange}
              disabled={disabled}
              className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className}`}
            />
          )
        ) : register ? (
          <input
            {...register}
            type={type ? type : 'text'}
            placeholder={placeholder}
            value={value ?? ''}
            maxLength={maxLength}
            disabled={disabled}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className} ${
              isDirty ? 'dirty' : ''
            }`}
          />
        ) : (
          <input
            type={type ? type : 'text'}
            placeholder={placeholder}
            value={value ?? ''}
            maxLength={maxLength}
            onChange={onChange}
            disabled={disabled}
            className={`flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm ${className}`}
          />
        )}
      </div>
    </div>
  );
}
