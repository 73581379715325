import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Display Title',
    accessor: 'display_title',
  },
  {
    Header: 'Url',
    accessor: 'url',
  },
  { Header: 'Published', accessor: 'published' },
];

export default function Guides () {
  return (
    <PageListView
      header='Guides'
      tableProps={{
        type: 'guides',
        route: 'guides',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
