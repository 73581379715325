import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Keyword', accessor: 'macro_keyword' },
  { Header: 'Description', accessor: 'description' },
];

export default function Macros() {
  return (
    <PageListView
      header='Macros'
      rightButton={{
        title: 'Create New',
        url: '/macros/new',
      }}
      tableProps={{
        type: 'macros',
        route: 'macros',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
