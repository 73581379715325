import React from 'react';
import ConsultCard from '../ConsultCard/ConsultCard';

export default function ConsultSection({
  consults,
  title = 'Consults',
  emptyMsg = 'There are no related consults',
}) {
  return (
    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1'>
      <div className='px-4 lg:px-8 pt-8'>
        <h2 className='font-serif font-semibold m-0 text-lg'>{title}</h2>
      </div>
      {!consults?.length ? (
        <div className='px-4 lg:px-8 pt-4 pb-8'>
          <div className='flex-1 max-w-full'>
            <div className='flex flex-col relative'>
              <span className='flex-auto relative w-full m-0 text-sm'>
                {emptyMsg}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className='px-4 lg:px-8 pt-4 pb-8'>
          {consults
            .sort((a, b) => (a.scheduled_dt > b.scheduled_dt ? -1 : 1))
            .map(consult => (
              <ConsultCard consult={consult} key={consult.id} />
            ))}
        </div>
      )}
    </div>
  );
}
