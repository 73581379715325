//ICON LIBRARY - SPECIAL ICONS
import { ReactComponent as barsFullSVG } from '../assets/images/icon/special/barsFull.svg';
import { ReactComponent as barsMidSVG } from '../assets/images/icon/special/barsMid.svg';
import { ReactComponent as barsLowSVG } from '../assets/images/icon/special/barsLow.svg';
import { ReactComponent as barsEmptySVG } from '../assets/images/icon/special/barsEmpty.svg';

//ICON LIBRARY - OUTLINED ONLY
import { ReactComponent as chevronBackSVG } from '../assets/images/icon/outlined/chevron-back.svg';
import { ReactComponent as chevronDownSVG } from '../assets/images/icon/outlined/chevron-down.svg';
import { ReactComponent as chevronForwardSVG } from '../assets/images/icon/outlined/chevron-forward.svg';
import { ReactComponent as chevronUpSVG } from '../assets/images/icon/outlined/chevron-up.svg';
import { ReactComponent as closeSmSVG } from '../assets/images/icon/outlined/close-sm.svg';
import { ReactComponent as collapseSVG } from '../assets/images/icon/outlined/collapse.svg';
import { ReactComponent as deliverySVG } from '../assets/images/icon/outlined/delivery.svg';
import { ReactComponent as doneSVG } from '../assets/images/icon/outlined/done.svg';
import { ReactComponent as downSmallSVG } from '../assets/images/icon/outlined/down-small.svg';
import { ReactComponent as expandSVG } from '../assets/images/icon/outlined/expand.svg';
import { ReactComponent as filterLinesSVG } from '../assets/images/icon/outlined/filter-lines.svg';
import { ReactComponent as leftSmallSVG } from '../assets/images/icon/outlined/left-small.svg';
import { ReactComponent as menuSVG } from '../assets/images/icon/outlined/menu.svg';
import { ReactComponent as minusSVG } from '../assets/images/icon/outlined/minus.svg';
import { ReactComponent as plusSVG } from '../assets/images/icon/outlined/plus.svg';
import { ReactComponent as refreshCcwSVG } from '../assets/images/icon/outlined/refresh-ccw.svg';
import { ReactComponent as refreshCwSVG } from '../assets/images/icon/outlined/refresh-cw.svg';
import { ReactComponent as rightSmallSVG } from '../assets/images/icon/outlined/right-small.svg';
import { ReactComponent as upSmallSVG } from '../assets/images/icon/outlined/up-small.svg';
//ICON LIBRARY - OUTLINED VERSION
import { ReactComponent as addSVG } from '../assets/images/icon/outlined/add.svg';
import { ReactComponent as alertCircleSVG } from '../assets/images/icon/outlined/alert-circle.svg';
import { ReactComponent as alertTriangleSVG } from '../assets/images/icon/outlined/alert-triangle.svg';
import { ReactComponent as atSignSVG } from '../assets/images/icon/outlined/at-sign.svg';
import { ReactComponent as babyBottleSVG } from '../assets/images/icon/outlined/baby-bottle.svg';
import { ReactComponent as babyFeetSVG } from '../assets/images/icon/outlined/baby-feet.svg';
import { ReactComponent as babySVG } from '../assets/images/icon/outlined/baby.svg';
import { ReactComponent as bacteriaSVG } from '../assets/images/icon/outlined/bacteria.svg';
import { ReactComponent as bandageSVG } from '../assets/images/icon/outlined/bandage.svg';
import { ReactComponent as barChart01SVG } from '../assets/images/icon/outlined/bar-chart-01.svg';
import { ReactComponent as barChart02SVG } from '../assets/images/icon/outlined/bar-chart-02.svg';
import { ReactComponent as barChart03SVG } from '../assets/images/icon/outlined/bar-chart-03.svg';
import { ReactComponent as barChartCircleSVG } from '../assets/images/icon/outlined/bar-chart-circle.svg';
import { ReactComponent as barChartSquare01SVG } from '../assets/images/icon/outlined/bar-chart-square-01.svg';
import { ReactComponent as barChartSquare02SVG } from '../assets/images/icon/outlined/bar-chart-square-02.svg';
import { ReactComponent as barLineChartSVG } from '../assets/images/icon/outlined/bar-line-chart.svg';
import { ReactComponent as beingSickSVG } from '../assets/images/icon/outlined/being-sick.svg';
import { ReactComponent as bibSVG } from '../assets/images/icon/outlined/bib.svg';
import { ReactComponent as bifidoSVG } from '../assets/images/icon/outlined/bifido.svg';
import { ReactComponent as bodyCellsSVG } from '../assets/images/icon/outlined/body-cells.svg';
import { ReactComponent as bookSVG } from '../assets/images/icon/outlined/book.svg';
import { ReactComponent as boxSVG } from '../assets/images/icon/outlined/box.svg';
import { ReactComponent as brickSVG } from '../assets/images/icon/outlined/brick.svg';
import { ReactComponent as calendarSVG } from '../assets/images/icon/outlined/calendar.svg';
import { ReactComponent as chartBreakoutCircleSVG } from '../assets/images/icon/outlined/chart-breakout-circle.svg';
import { ReactComponent as chartBreakoutSquareSVG } from '../assets/images/icon/outlined/chart-breakout-square.svg';
import { ReactComponent as checkSVG } from '../assets/images/icon/outlined/Check.svg';
import { ReactComponent as checkDoneSVG } from '../assets/images/icon/outlined/check-done.svg';
import { ReactComponent as checkSquareSVG } from '../assets/images/icon/outlined/check-square.svg';
import { ReactComponent as circleSVG } from '../assets/images/icon/outlined/circle.svg';
import { ReactComponent as clamtobacterSVG } from '../assets/images/icon/outlined/clamtobacter.svg';
import { ReactComponent as clipboardCheckSVG } from '../assets/images/icon/outlined/clipboard-check.svg';
import { ReactComponent as clipboardXSVG } from '../assets/images/icon/outlined/clipboard-x.svg';
import { ReactComponent as clipboardSVG } from '../assets/images/icon/outlined/clipboard.svg';
import { ReactComponent as clockSVG } from '../assets/images/icon/outlined/clock.svg';
import { ReactComponent as copySVG } from '../assets/images/icon/outlined/copy.svg';
import { ReactComponent as dnaHelixSVG } from '../assets/images/icon/outlined/dna-helix.svg';
import { ReactComponent as doNotDisturbSVG } from '../assets/images/icon/outlined/do-not-disturb.svg';
import { ReactComponent as dogPawSVG } from '../assets/images/icon/outlined/dog-paw.svg';
import { ReactComponent as eColiSVG } from '../assets/images/icon/outlined/e-coli.svg';
import { ReactComponent as edit01SVG } from '../assets/images/icon/outlined/edit-01.svg';
import { ReactComponent as edit02SVG } from '../assets/images/icon/outlined/edit-02.svg';
import { ReactComponent as enterococcusSVG } from '../assets/images/icon/outlined/enterococcus.svg';
import { ReactComponent as eyeOffSVG } from '../assets/images/icon/outlined/eye-off.svg';
import { ReactComponent as eyeSVG } from '../assets/images/icon/outlined/eye.svg';
import { ReactComponent as faceContentSVG } from '../assets/images/icon/outlined/face-content.svg';
import { ReactComponent as faceFrownSVG } from '../assets/images/icon/outlined/face-frown.svg';
import { ReactComponent as faceHappySVG } from '../assets/images/icon/outlined/face-happy.svg';
import { ReactComponent as faceMaskSVG } from '../assets/images/icon/outlined/face-mask.svg';
import { ReactComponent as faceNeutralSVG } from '../assets/images/icon/outlined/face-neutral.svg';
import { ReactComponent as faceSadSVG } from '../assets/images/icon/outlined/face-sad.svg';
import { ReactComponent as faceSmileSVG } from '../assets/images/icon/outlined/face-smile.svg';
import { ReactComponent as faceWinkSVG } from '../assets/images/icon/outlined/face-wink.svg';
import { ReactComponent as feedingBabySVG } from '../assets/images/icon/outlined/feeding-baby.svg';
import { ReactComponent as fermentedSVG } from '../assets/images/icon/outlined/fermented.svg';
import { ReactComponent as file01SVG } from '../assets/images/icon/outlined/file-01.svg';
import { ReactComponent as file02SVG } from '../assets/images/icon/outlined/file-02.svg';
import { ReactComponent as file03SVG } from '../assets/images/icon/outlined/file-03.svg';
import { ReactComponent as file04SVG } from '../assets/images/icon/outlined/file-04.svg';
import { ReactComponent as file05SVG } from '../assets/images/icon/outlined/file-05.svg';
import { ReactComponent as file06SVG } from '../assets/images/icon/outlined/file-06.svg';
import { ReactComponent as fileAttachmentSVG } from '../assets/images/icon/outlined/file-attachment.svg';
import { ReactComponent as fileCheck01SVG } from '../assets/images/icon/outlined/file-check-01.svg';
import { ReactComponent as fileCheck02SVG } from '../assets/images/icon/outlined/file-check-02.svg';
import { ReactComponent as fileHeart01SVG } from '../assets/images/icon/outlined/file-heart-01.svg';
import { ReactComponent as fileHeart02SVG } from '../assets/images/icon/outlined/file-heart-02.svg';
import { ReactComponent as filePlus01SVG } from '../assets/images/icon/outlined/file-plus-01.svg';
import { ReactComponent as filePlus02SVG } from '../assets/images/icon/outlined/file-plus-02.svg';
import { ReactComponent as fileQuestion01SVG } from '../assets/images/icon/outlined/file-question-01.svg';
import { ReactComponent as fileQuestion02SVG } from '../assets/images/icon/outlined/file-question-02.svg';
import { ReactComponent as fileQuestion03SVG } from '../assets/images/icon/outlined/file-question-03.svg';
import { ReactComponent as fileSearch01SVG } from '../assets/images/icon/outlined/file-search-01.svg';
import { ReactComponent as fileSearch02SVG } from '../assets/images/icon/outlined/file-search-02.svg';
import { ReactComponent as flagSVG } from '../assets/images/icon/outlined/flag.svg';
import { ReactComponent as flashSVG } from '../assets/images/icon/outlined/flash.svg';
import { ReactComponent as flaxSeedsSVG } from '../assets/images/icon/outlined/flax-seeds.svg';
import { ReactComponent as giftSVG } from '../assets/images/icon/outlined/gift.svg';
import { ReactComponent as heartHealthSVG } from '../assets/images/icon/outlined/heart-health.svg';
import { ReactComponent as heartsSVG } from '../assets/images/icon/outlined/hearts.svg';
import { ReactComponent as helpSVG } from '../assets/images/icon/outlined/help.svg';
import { ReactComponent as highRiskSVG } from '../assets/images/icon/outlined/high-risk.svg';
import { ReactComponent as historySVG } from '../assets/images/icon/outlined/history.svg';
import { ReactComponent as homeSVG } from '../assets/images/icon/outlined/home.svg';
import { ReactComponent as horizontalBarChartSVG } from '../assets/images/icon/outlined/horizontal-bar-chart.svg';
import { ReactComponent as housekeepingSVG } from '../assets/images/icon/outlined/housekeeping.svg';
import { ReactComponent as infoCircleSVG } from '../assets/images/icon/outlined/info-circle.svg';
import { ReactComponent as intestineSVG } from '../assets/images/icon/outlined/intestine.svg';
import { ReactComponent as lightOnSVG } from '../assets/images/icon/outlined/light-on.svg';
import { ReactComponent as lineChartUp01SVG } from '../assets/images/icon/outlined/line-chart-up-01.svg';
import { ReactComponent as lineChartUp02SVG } from '../assets/images/icon/outlined/line-chart-up-02.svg';
import { ReactComponent as lineChartUpSVG } from '../assets/images/icon/outlined/line-chart-up.svg';
import { ReactComponent as loadingSVG } from '../assets/images/icon/outlined/loading.svg';
import { ReactComponent as lockSVG } from '../assets/images/icon/outlined/lock.svg';
import { ReactComponent as logOutSVG } from '../assets/images/icon/outlined/log-out.svg';
import { ReactComponent as lotusSVG } from '../assets/images/icon/outlined/lotus.svg';
import { ReactComponent as magicWandSVG } from '../assets/images/icon/outlined/magic-wand.svg';
import { ReactComponent as mailSVG } from '../assets/images/icon/outlined/mail.svg';
import { ReactComponent as markerPin01SVG } from '../assets/images/icon/outlined/markerPin01.svg';
import { ReactComponent as markerPin02SVG } from '../assets/images/icon/outlined/markerPin02.svg';
import { ReactComponent as microbiomeSVG } from '../assets/images/icon/outlined/microbiome.svg';
import { ReactComponent as microscopeSVG } from '../assets/images/icon/outlined/microscope.svg';
import { ReactComponent as mortarAndPestleSVG } from '../assets/images/icon/outlined/mortar-and-pestle.svg';
import { ReactComponent as muscleSVG } from '../assets/images/icon/outlined/muscle.svg';
import { ReactComponent as nappySVG } from '../assets/images/icon/outlined/nappy.svg';
import { ReactComponent as naturalFoodSVG } from '../assets/images/icon/outlined/natural-food.svg';
import { ReactComponent as notificationsSVG } from '../assets/images/icon/outlined/notifications.svg';
import { ReactComponent as nutSVG } from '../assets/images/icon/outlined/nut.svg';
import { ReactComponent as organicFoodSVG } from '../assets/images/icon/outlined/organic-food.svg';
import { ReactComponent as pacifierSVG } from '../assets/images/icon/outlined/pacifier.svg';
import { ReactComponent as paperclipSVG } from '../assets/images/icon/outlined/paperclip.svg';
import { ReactComponent as pieChart01SVG } from '../assets/images/icon/outlined/pie-chart-01.svg';
import { ReactComponent as pieChart02SVG } from '../assets/images/icon/outlined/pie-chart-02.svg';
import { ReactComponent as pieChart03SVG } from '../assets/images/icon/outlined/pie-chart-03.svg';
import { ReactComponent as pieChart04SVG } from '../assets/images/icon/outlined/pie-chart-04.svg';
import { ReactComponent as pillSVG } from '../assets/images/icon/outlined/pill.svg';
import { ReactComponent as pillsSVG } from '../assets/images/icon/outlined/pills.svg';
import { ReactComponent as pregnantSVG } from '../assets/images/icon/outlined/pregnant.svg';
import { ReactComponent as prenatalYogaSVG } from '../assets/images/icon/outlined/prenatal-yoga.svg';
import { ReactComponent as priceTagSVG } from '../assets/images/icon/outlined/price-tag.svg';
import { ReactComponent as printerSVG } from '../assets/images/icon/outlined/printer.svg';
import { ReactComponent as repeatSVG } from '../assets/images/icon/outlined/repeat.svg';
import { ReactComponent as sadGhostSVG } from '../assets/images/icon/outlined/sad-ghost.svg';
import { ReactComponent as scaleSVG } from '../assets/images/icon/outlined/scale.svg';
import { ReactComponent as seedingSVG } from '../assets/images/icon/outlined/seeding.svg';
import { ReactComponent as sendSVG } from '../assets/images/icon/outlined/send.svg';
import { ReactComponent as settingsSVG } from '../assets/images/icon/outlined/settings.svg';
import { ReactComponent as sheepSVG } from '../assets/images/icon/outlined/sheep.svg';
import { ReactComponent as starSVG } from '../assets/images/icon/outlined/star.svg';
import { ReactComponent as stars01SVG } from '../assets/images/icon/outlined/stars-01.svg';
import { ReactComponent as stars02SVG } from '../assets/images/icon/outlined/stars-02.svg';
import { ReactComponent as stars03SVG } from '../assets/images/icon/outlined/stars-03.svg';
import { ReactComponent as stethoscopeSVG } from '../assets/images/icon/outlined/stethoscope.svg';
import { ReactComponent as stickerCircleSVG } from '../assets/images/icon/outlined/sticker-circle.svg';
import { ReactComponent as stickerSquareSVG } from '../assets/images/icon/outlined/sticker-square.svg';
import { ReactComponent as stomachSVG } from '../assets/images/icon/outlined/stomach.svg';
import { ReactComponent as swabSVG } from '../assets/images/icon/outlined/swab.svg';
import { ReactComponent as syringeSVG } from '../assets/images/icon/outlined/syringe.svg';
import { ReactComponent as targetSVG } from '../assets/images/icon/outlined/target.svg';
import { ReactComponent as todaySVG } from '../assets/images/icon/outlined/today.svg';
import { ReactComponent as toolSVG } from '../assets/images/icon/outlined/tool.svg';
import { ReactComponent as trashSVG } from '../assets/images/icon/outlined/trash.svg';
import { ReactComponent as trendDownSVG } from '../assets/images/icon/outlined/trend-down.svg';
import { ReactComponent as trendUp01SVG } from '../assets/images/icon/outlined/trend-up-01.svg';
import { ReactComponent as trendUp02SVG } from '../assets/images/icon/outlined/trend-up-02.svg';
import { ReactComponent as trophySVG } from '../assets/images/icon/outlined/trophy.svg';
import { ReactComponent as userCheckSVG } from '../assets/images/icon/outlined/user-check.svg';
import { ReactComponent as userEditSVG } from '../assets/images/icon/outlined/user-edit.svg';
import { ReactComponent as userMultipleSVG } from '../assets/images/icon/outlined/user-multiple.svg';
import { ReactComponent as userSingleSVG } from '../assets/images/icon/outlined/user-single.svg';
import { ReactComponent as virusSVG } from '../assets/images/icon/outlined/virus.svg';
import { ReactComponent as zapCircleSVG } from '../assets/images/icon/outlined/zap-circle.svg';
import { ReactComponent as zapSquareSVG } from '../assets/images/icon/outlined/zap-square.svg';

//ICON LIBRARY - FILLED VERSION
import { ReactComponent as addSVGFilled } from '../assets/images/icon/filled/add.svg';
import { ReactComponent as alertCircleSVGFilled } from '../assets/images/icon/filled/alert-circle.svg';
import { ReactComponent as alertTriangleSVGFilled } from '../assets/images/icon/filled/alert-triangle.svg';
import { ReactComponent as atSignSVGFilled } from '../assets/images/icon/filled/at-sign.svg';
import { ReactComponent as babyBottleSVGFilled } from '../assets/images/icon/filled/baby-bottle.svg';
import { ReactComponent as babyFeetSVGFilled } from '../assets/images/icon/filled/baby-feet.svg';
import { ReactComponent as babySVGFilled } from '../assets/images/icon/filled/baby.svg';
import { ReactComponent as bacteriaSVGFilled } from '../assets/images/icon/filled/bacteria.svg';
import { ReactComponent as bandageSVGFilled } from '../assets/images/icon/filled/bandage.svg';
import { ReactComponent as barChart01SVGFilled } from '../assets/images/icon/filled/bar-chart-01.svg';
import { ReactComponent as barChart02SVGFilled } from '../assets/images/icon/filled/bar-chart-02.svg';
import { ReactComponent as barChart03SVGFilled } from '../assets/images/icon/filled/bar-chart-03.svg';
import { ReactComponent as barChartCircleSVGFilled } from '../assets/images/icon/filled/bar-chart-circle.svg';
import { ReactComponent as barChartSquare01SVGFilled } from '../assets/images/icon/filled/bar-chart-square-01.svg';
import { ReactComponent as barChartSquare02SVGFilled } from '../assets/images/icon/filled/bar-chart-square-02.svg';
import { ReactComponent as barLineChartSVGFilled } from '../assets/images/icon/filled/bar-line-chart.svg';
import { ReactComponent as beingSickSVGFilled } from '../assets/images/icon/filled/being-sick.svg';
import { ReactComponent as bibSVGFilled } from '../assets/images/icon/filled/bib.svg';
import { ReactComponent as bifidoSVGFilled } from '../assets/images/icon/filled/bifido.svg';
import { ReactComponent as bodyCellsSVGFilled } from '../assets/images/icon/filled/body-cells.svg';
import { ReactComponent as bookSVGFilled } from '../assets/images/icon/filled/book.svg';
import { ReactComponent as boxSVGFilled } from '../assets/images/icon/filled/box.svg';
import { ReactComponent as brickSVGFilled } from '../assets/images/icon/filled/brick.svg';
import { ReactComponent as calendarSVGFilled } from '../assets/images/icon/filled/calendar.svg';
import { ReactComponent as copySVGFilled } from '../assets/images/icon/filled/copy.svg';
import { ReactComponent as chartBreakoutCircleSVGFilled } from '../assets/images/icon/filled/chart-breakout-circle.svg';
import { ReactComponent as chartBreakoutSquareSVGFilled } from '../assets/images/icon/filled/chart-breakout-square.svg';
import { ReactComponent as checkSVGFilled } from '../assets/images/icon/filled/check.svg';
import { ReactComponent as checkDoneSVGFilled } from '../assets/images/icon/filled/check-done.svg';
import { ReactComponent as checkSquareSVGFilled } from '../assets/images/icon/filled/check-square.svg';
import { ReactComponent as circleSVGFilled } from '../assets/images/icon/filled/circle.svg';
import { ReactComponent as clamtobacterSVGFilled } from '../assets/images/icon/filled/clamtobacter.svg';
import { ReactComponent as clipboardCheckSVGFilled } from '../assets/images/icon/filled/clipboard-check.svg';
import { ReactComponent as clipboardXSVGFilled } from '../assets/images/icon/filled/clipboard-x.svg';
import { ReactComponent as clipboardSVGFilled } from '../assets/images/icon/filled/clipboard.svg';
import { ReactComponent as clockSVGFilled } from '../assets/images/icon/filled/clock.svg';
import { ReactComponent as deliverySVGFilled } from '../assets/images/icon/filled/delivery.svg';
import { ReactComponent as dnaHelixSVGFilled } from '../assets/images/icon/filled/dna-helix.svg';
import { ReactComponent as doNotDisturbSVGFilled } from '../assets/images/icon/filled/do-not-disturb.svg';
import { ReactComponent as dogPawSVGFilled } from '../assets/images/icon/filled/dog-paw.svg';
import { ReactComponent as eColiSVGFilled } from '../assets/images/icon/filled/e-coli.svg';
import { ReactComponent as edit01SVGFilled } from '../assets/images/icon/filled/edit-01.svg';
import { ReactComponent as edit02SVGFilled } from '../assets/images/icon/filled/edit-02.svg';
import { ReactComponent as enterococcusSVGFilled } from '../assets/images/icon/filled/enterococcus.svg';
import { ReactComponent as eyeOffSVGFilled } from '../assets/images/icon/filled/eye-off.svg';
import { ReactComponent as eyeSVGFilled } from '../assets/images/icon/filled/eye.svg';
import { ReactComponent as faceContentSVGFilled } from '../assets/images/icon/filled/face-content.svg';
import { ReactComponent as faceFrownSVGFilled } from '../assets/images/icon/filled/face-frown.svg';
import { ReactComponent as faceHappySVGFilled } from '../assets/images/icon/filled/face-happy.svg';
import { ReactComponent as faceMaskSVGFilled } from '../assets/images/icon/filled/face-mask.svg';
import { ReactComponent as faceNeutralSVGFilled } from '../assets/images/icon/filled/face-neutral.svg';
import { ReactComponent as faceSadSVGFilled } from '../assets/images/icon/filled/face-sad.svg';
import { ReactComponent as faceSmileSVGFilled } from '../assets/images/icon/filled/face-smile.svg';
import { ReactComponent as faceWinkSVGFilled } from '../assets/images/icon/filled/face-wink.svg';
import { ReactComponent as feedingBabySVGFilled } from '../assets/images/icon/filled/feeding-baby.svg';
import { ReactComponent as fermentedSVGFilled } from '../assets/images/icon/filled/fermented.svg';
import { ReactComponent as file01SVGFilled } from '../assets/images/icon/filled/file-01.svg';
import { ReactComponent as file02SVGFilled } from '../assets/images/icon/filled/file-02.svg';
import { ReactComponent as file03SVGFilled } from '../assets/images/icon/filled/file-03.svg';
import { ReactComponent as file04SVGFilled } from '../assets/images/icon/filled/file-04.svg';
import { ReactComponent as file05SVGFilled } from '../assets/images/icon/filled/file-05.svg';
import { ReactComponent as file06SVGFilled } from '../assets/images/icon/filled/file-06.svg';
import { ReactComponent as fileAttachmentSVGFilled } from '../assets/images/icon/filled/file-attachment.svg';
import { ReactComponent as fileCheck01SVGFilled } from '../assets/images/icon/filled/file-check-01.svg';
import { ReactComponent as fileCheck02SVGFilled } from '../assets/images/icon/filled/file-check-02.svg';
import { ReactComponent as fileHeart01SVGFilled } from '../assets/images/icon/filled/file-heart-01.svg';
import { ReactComponent as fileHeart02SVGFilled } from '../assets/images/icon/filled/file-heart-02.svg';
import { ReactComponent as filePlus01SVGFilled } from '../assets/images/icon/filled/file-plus-01.svg';
import { ReactComponent as filePlus02SVGFilled } from '../assets/images/icon/filled/file-plus-02.svg';
import { ReactComponent as fileQuestion01SVGFilled } from '../assets/images/icon/filled/file-question-01.svg';
import { ReactComponent as fileQuestion02SVGFilled } from '../assets/images/icon/filled/file-question-02.svg';
import { ReactComponent as fileQuestion03SVGFilled } from '../assets/images/icon/filled/file-question-03.svg';
import { ReactComponent as fileSearch01SVGFilled } from '../assets/images/icon/filled/file-search-01.svg';
import { ReactComponent as fileSearch02SVGFilled } from '../assets/images/icon/filled/file-search-02.svg';
import { ReactComponent as flagSVGFilled } from '../assets/images/icon/filled/flag.svg';
import { ReactComponent as flashSVGFilled } from '../assets/images/icon/filled/flash.svg';
import { ReactComponent as flaxSeedsSVGFilled } from '../assets/images/icon/filled/flax-seeds.svg';
import { ReactComponent as giftSVGFilled } from '../assets/images/icon/filled/gift.svg';
import { ReactComponent as heartHealthSVGFilled } from '../assets/images/icon/filled/heart-health.svg';
import { ReactComponent as heartsSVGFilled } from '../assets/images/icon/filled/hearts.svg';
import { ReactComponent as helpSVGFilled } from '../assets/images/icon/filled/help.svg';
import { ReactComponent as highRiskSVGFilled } from '../assets/images/icon/filled/high-risk.svg';
import { ReactComponent as historySVGFilled } from '../assets/images/icon/filled/history.svg';
import { ReactComponent as homeSVGFilled } from '../assets/images/icon/filled/home.svg';
import { ReactComponent as horizontalBarChartSVGFilled } from '../assets/images/icon/filled/horizontal-bar-chart.svg';
import { ReactComponent as housekeepingSVGFilled } from '../assets/images/icon/filled/housekeeping.svg';
import { ReactComponent as infoCircleSVGFilled } from '../assets/images/icon/filled/info-circle.svg';
import { ReactComponent as intestineSVGFilled } from '../assets/images/icon/filled/intestine.svg';
import { ReactComponent as lightOnSVGFilled } from '../assets/images/icon/filled/light-on.svg';
import { ReactComponent as lineChartUp01SVGFilled } from '../assets/images/icon/filled/line-chart-up-01.svg';
import { ReactComponent as lineChartUp02SVGFilled } from '../assets/images/icon/filled/line-chart-up-02.svg';
import { ReactComponent as lineChartUpSVGFilled } from '../assets/images/icon/filled/line-chart-up.svg';
import { ReactComponent as loadingSVGFilled } from '../assets/images/icon/filled/loading.svg';
import { ReactComponent as lockSVGFilled } from '../assets/images/icon/filled/lock.svg';
import { ReactComponent as logOutSVGFilled } from '../assets/images/icon/filled/log-out.svg';
import { ReactComponent as lotusSVGFilled } from '../assets/images/icon/filled/lotus.svg';
import { ReactComponent as magicWandSVGFilled } from '../assets/images/icon/filled/magic-wand.svg';
import { ReactComponent as mailSVGFilled } from '../assets/images/icon/filled/mail.svg';
import { ReactComponent as markerPin01SVGFilled } from '../assets/images/icon/filled/markerPin01.svg';
import { ReactComponent as markerPin02SVGFilled } from '../assets/images/icon/filled/markerPin02.svg';
import { ReactComponent as microbiomeSVGFilled } from '../assets/images/icon/filled/microbiome.svg';
import { ReactComponent as microscopeSVGFilled } from '../assets/images/icon/filled/microscope.svg';
import { ReactComponent as mortarAndPestleSVGFilled } from '../assets/images/icon/filled/mortar-and-pestle.svg';
import { ReactComponent as muscleSVGFilled } from '../assets/images/icon/filled/muscle.svg';
import { ReactComponent as nappySVGFilled } from '../assets/images/icon/filled/nappy.svg';
import { ReactComponent as naturalFoodSVGFilled } from '../assets/images/icon/filled/natural-food.svg';
import { ReactComponent as notificationsSVGFilled } from '../assets/images/icon/filled/notifications.svg';
import { ReactComponent as nutSVGFilled } from '../assets/images/icon/filled/nut.svg';
import { ReactComponent as organicFoodSVGFilled } from '../assets/images/icon/filled/organic-food.svg';
import { ReactComponent as pacifierSVGFilled } from '../assets/images/icon/filled/pacifier.svg';
import { ReactComponent as paperclipSVGFilled } from '../assets/images/icon/filled/paperclip.svg';
import { ReactComponent as pieChart01SVGFilled } from '../assets/images/icon/filled/pie-chart-01.svg';
import { ReactComponent as pieChart02SVGFilled } from '../assets/images/icon/filled/pie-chart-02.svg';
import { ReactComponent as pieChart03SVGFilled } from '../assets/images/icon/filled/pie-chart-03.svg';
import { ReactComponent as pieChart04SVGFilled } from '../assets/images/icon/filled/pie-chart-04.svg';
import { ReactComponent as pillSVGFilled } from '../assets/images/icon/filled/pill.svg';
import { ReactComponent as pillsSVGFilled } from '../assets/images/icon/filled/pills.svg';
import { ReactComponent as pregnantSVGFilled } from '../assets/images/icon/filled/pregnant.svg';
import { ReactComponent as prenatalYogaSVGFilled } from '../assets/images/icon/filled/prenatal-yoga.svg';
import { ReactComponent as priceTagSVGFilled } from '../assets/images/icon/filled/price-tag.svg';
import { ReactComponent as printerSVGFilled } from '../assets/images/icon/filled/printer.svg';
import { ReactComponent as repeatSVGFilled } from '../assets/images/icon/filled/repeat.svg';
import { ReactComponent as sadGhostSVGFilled } from '../assets/images/icon/filled/sad-ghost.svg';
import { ReactComponent as scaleSVGFilled } from '../assets/images/icon/filled/scale.svg';
import { ReactComponent as seedingSVGFilled } from '../assets/images/icon/filled/seeding.svg';
import { ReactComponent as sendSVGFilled } from '../assets/images/icon/filled/send.svg';
import { ReactComponent as settingsSVGFilled } from '../assets/images/icon/filled/settings.svg';
import { ReactComponent as sheepSVGFilled } from '../assets/images/icon/filled/sheep.svg';
import { ReactComponent as starSVGFilled } from '../assets/images/icon/filled/star.svg';
import { ReactComponent as stars01SVGFilled } from '../assets/images/icon/filled/stars-01.svg';
import { ReactComponent as stars02SVGFilled } from '../assets/images/icon/filled/stars-02.svg';
import { ReactComponent as stars03SVGFilled } from '../assets/images/icon/filled/stars-03.svg';
import { ReactComponent as stethoscopeSVGFilled } from '../assets/images/icon/filled/stethoscope.svg';
import { ReactComponent as stickerCircleSVGFilled } from '../assets/images/icon/filled/sticker-circle.svg';
import { ReactComponent as stickerSquareSVGFilled } from '../assets/images/icon/filled/sticker-square.svg';
import { ReactComponent as stomachSVGFilled } from '../assets/images/icon/filled/stomach.svg';
import { ReactComponent as swabSVGFilled } from '../assets/images/icon/filled/swab.svg';
import { ReactComponent as syringeSVGFilled } from '../assets/images/icon/filled/syringe.svg';
import { ReactComponent as targetSVGFilled } from '../assets/images/icon/filled/target.svg';
import { ReactComponent as todaySVGFilled } from '../assets/images/icon/filled/today.svg';
import { ReactComponent as toolSVGFilled } from '../assets/images/icon/filled/tool.svg';
import { ReactComponent as trashSVGFilled } from '../assets/images/icon/filled/trash.svg';
import { ReactComponent as trendDownSVGFilled } from '../assets/images/icon/filled/trend-down.svg';
import { ReactComponent as trendUp01SVGFilled } from '../assets/images/icon/filled/trend-up-01.svg';
import { ReactComponent as trendUp02SVGFilled } from '../assets/images/icon/filled/trend-up-02.svg';
import { ReactComponent as trophySVGFilled } from '../assets/images/icon/filled/trophy.svg';
import { ReactComponent as userCheckSVGFilled } from '../assets/images/icon/filled/user-check.svg';
import { ReactComponent as userEditSVGFilled } from '../assets/images/icon/filled/user-edit.svg';
import { ReactComponent as userMultipleSVGFilled } from '../assets/images/icon/filled/user-multiple.svg';
import { ReactComponent as userSingleSVGFilled } from '../assets/images/icon/filled/user-single.svg';
import { ReactComponent as virusSVGFilled } from '../assets/images/icon/filled/virus.svg';
import { ReactComponent as zapCircleSVGFilled } from '../assets/images/icon/filled/zap-circle.svg';
import { ReactComponent as zapSquareSVGFilled } from '../assets/images/icon/filled/zap-square.svg';

import { ReactComponent as NumberBlocksSVG } from '../assets/images/icon/numberBlocks.svg';
import { ReactComponent as LetterBlocksSVG } from '../assets/images/icon/letterBlocks.svg';

export const ICON_SVG = {
  // down from here is the complete list of the icons in the Figma Icon Library (SPECIAL ONLY)
  barsFull: barsFullSVG,
  barsMid: barsMidSVG,
  barsLow: barsLowSVG,
  barsEmpty: barsEmptySVG,
  // down from here is the complete list of the icons in the Figma Icon Library (OUTLINED ONLY)
  chevronBack: chevronBackSVG,
  chevronDown: chevronDownSVG,
  chevronForward: chevronForwardSVG,
  chevronUp: chevronUpSVG,
  closeSm: closeSmSVG,
  collapse: collapseSVG,
  copy: copySVG,
  done: doneSVG,
  downSmall: downSmallSVG,
  expand: expandSVG,
  filterLines: filterLinesSVG,
  leftSmall: leftSmallSVG,
  menu: menuSVG,
  minus: minusSVG,
  plus: plusSVG,
  refreshCcw: refreshCcwSVG,
  refreshCw: refreshCwSVG,
  rightSmall: rightSmallSVG,
  upSmall: upSmallSVG,
  numberBlocks: NumberBlocksSVG,
  letterBlocks: LetterBlocksSVG,

  // down from here is the complete list of the icons in the Figma Icon Library (OUTLINED VERSION)
  add: addSVG,
  alertCircle: alertCircleSVG,
  alertTriangle: alertTriangleSVG,
  atSign: atSignSVG,
  babyBottle: babyBottleSVG,
  babyFeet: babyFeetSVG,
  baby: babySVG,
  bacteria: bacteriaSVG,
  bandage: bandageSVG,
  barChart01: barChart01SVG,
  barChart02: barChart02SVG,
  barChart03: barChart03SVG,
  barChartCircle: barChartCircleSVG,
  barChartSquare01: barChartSquare01SVG,
  barChartSquare02: barChartSquare02SVG,
  barLineChart: barLineChartSVG,
  beingSick: beingSickSVG,
  bib: bibSVG,
  bifido: bifidoSVG,
  bodyCells: bodyCellsSVG,
  book: bookSVG,
  box: boxSVG,
  brick: brickSVG,
  calendar: calendarSVG,
  chartBreakoutCircle: chartBreakoutCircleSVG,
  chartBreakoutSquare: chartBreakoutSquareSVG,
  check: checkSVG,
  checkDone: checkDoneSVG,
  checkSquare: checkSquareSVG,
  circle: circleSVG,
  clamtobacter: clamtobacterSVG,
  clipboardCheck: clipboardCheckSVG,
  clipboardX: clipboardXSVG,
  clipboard: clipboardSVG,
  clock: clockSVG,
  delivery: deliverySVG,
  dnaHelix: dnaHelixSVG,
  doNotDisturb: doNotDisturbSVG,
  dogPaw: dogPawSVG,
  eColi: eColiSVG,
  edit01: edit01SVG,
  edit02: edit02SVG,
  enterococcus: enterococcusSVG,
  eyeOff: eyeOffSVG,
  eye: eyeSVG,
  faceContent: faceContentSVG,
  faceFrown: faceFrownSVG,
  faceHappy: faceHappySVG,
  faceMask: faceMaskSVG,
  faceNeutral: faceNeutralSVG,
  faceSad: faceSadSVG,
  faceSmile: faceSmileSVG,
  faceWink: faceWinkSVG,
  feedingBaby: feedingBabySVG,
  fermented: fermentedSVG,
  file01: file01SVG,
  file02: file02SVG,
  file03: file03SVG,
  file04: file04SVG,
  file05: file05SVG,
  file06: file06SVG,
  fileAttachment: fileAttachmentSVG,
  fileCheck01: fileCheck01SVG,
  fileCheck02: fileCheck02SVG,
  fileHeart01: fileHeart01SVG,
  fileHeart02: fileHeart02SVG,
  filePlus01: filePlus01SVG,
  filePlus02: filePlus02SVG,
  fileQuestion01: fileQuestion01SVG,
  fileQuestion02: fileQuestion02SVG,
  fileQuestion03: fileQuestion03SVG,
  fileSearch01: fileSearch01SVG,
  fileSearch02: fileSearch02SVG,
  flag: flagSVG,
  flash: flashSVG,
  flaxSeeds: flaxSeedsSVG,
  gift: giftSVG,
  heartHealth: heartHealthSVG,
  hearts: heartsSVG,
  help: helpSVG,
  highRisk: highRiskSVG,
  history: historySVG,
  home: homeSVG,
  horizontalBarChart: horizontalBarChartSVG,
  housekeeping: housekeepingSVG,
  infoCircle: infoCircleSVG,
  intestine: intestineSVG,
  lightOn: lightOnSVG,
  lineChartUp01: lineChartUp01SVG,
  lineChartUp02: lineChartUp02SVG,
  lineChartUp: lineChartUpSVG,
  loading: loadingSVG,
  lock: lockSVG,
  logOut: logOutSVG,
  lotus: lotusSVG,
  magicWand: magicWandSVG,
  mail: mailSVG,
  markerPin01: markerPin01SVG,
  markerPin02: markerPin02SVG,
  microbiome: microbiomeSVG,
  microscope: microscopeSVG,
  mortarAndPestle: mortarAndPestleSVG,
  muscle: muscleSVG,
  nappy: nappySVG,
  naturalFood: naturalFoodSVG,
  notifications: notificationsSVG,
  nut: nutSVG,
  organicFood: organicFoodSVG,
  pacifier: pacifierSVG,
  paperclip: paperclipSVG,
  pieChart01: pieChart01SVG,
  pieChart02: pieChart02SVG,
  pieChart03: pieChart03SVG,
  pieChart04: pieChart04SVG,
  pill: pillSVG,
  pills: pillsSVG,
  pregnant: pregnantSVG,
  prenatalYoga: prenatalYogaSVG,
  priceTag: priceTagSVG,
  printer: printerSVG,
  repeat: repeatSVG,
  sadGhost: sadGhostSVG,
  scale: scaleSVG,
  seeding: seedingSVG,
  send: sendSVG,
  settings: settingsSVG,
  sheep: sheepSVG,
  star: starSVG,
  stars01: stars01SVG,
  stars02: stars02SVG,
  stars03: stars03SVG,
  stethoscope: stethoscopeSVG,
  stickerCircle: stickerCircleSVG,
  stickerSquare: stickerSquareSVG,
  stomach: stomachSVG,
  swab: swabSVG,
  syringe: syringeSVG,
  target: targetSVG,
  today: todaySVG,
  tool: toolSVG,
  trash: trashSVG,
  trendDown: trendDownSVG,
  trendUp01: trendUp01SVG,
  trendUp02: trendUp02SVG,
  trophy: trophySVG,
  userCheck: userCheckSVG,
  userEdit: userEditSVG,
  userMultiple: userMultipleSVG,
  userSingle: userSingleSVG,
  virus: virusSVG,
  zapCircle: zapCircleSVG,
  zapSquare: zapSquareSVG,

  // down from here is the complete list of the icons in the Figma Icon Library (FILLED VERSION)
  addFilled: addSVGFilled,
  alertCircleFilled: alertCircleSVGFilled,
  alertTriangleFilled: alertTriangleSVGFilled,
  atSignFilled: atSignSVGFilled,
  babyBottleFilled: babyBottleSVGFilled,
  babyFeetFilled: babyFeetSVGFilled,
  babyFilled: babySVGFilled,
  bacteriaFilled: bacteriaSVGFilled,
  bandageFilled: bandageSVGFilled,
  barChart01Filled: barChart01SVGFilled,
  barChart02Filled: barChart02SVGFilled,
  barChart03Filled: barChart03SVGFilled,
  barChartCircleFilled: barChartCircleSVGFilled,
  barChartSquare01Filled: barChartSquare01SVGFilled,
  barChartSquare02Filled: barChartSquare02SVGFilled,
  barLineChartFilled: barLineChartSVGFilled,
  beingSickFilled: beingSickSVGFilled,
  bibFilled: bibSVGFilled,
  bifidoFilled: bifidoSVGFilled,
  bodyCellsFilled: bodyCellsSVGFilled,
  bookFilled: bookSVGFilled,
  boxFilled: boxSVGFilled,
  brickFilled: brickSVGFilled,
  calendarFilled: calendarSVGFilled,
  chartBreakoutCircleFilled: chartBreakoutCircleSVGFilled,
  chartBreakoutSquareFilled: chartBreakoutSquareSVGFilled,
  checkFilled: checkSVGFilled,
  checkDoneFilled: checkDoneSVGFilled,
  checkSquareFilled: checkSquareSVGFilled,
  circleFilled: circleSVGFilled,
  clamtobacterFilled: clamtobacterSVGFilled,
  clipboardCheckFilled: clipboardCheckSVGFilled,
  clipboardXFilled: clipboardXSVGFilled,
  clipboardFilled: clipboardSVGFilled,
  clockFilled: clockSVGFilled,
  copySVGFilled: copySVGFilled,
  deliveryFilled: deliverySVGFilled,
  dnaHelixFilled: dnaHelixSVGFilled,
  doNotDisturbFilled: doNotDisturbSVGFilled,
  dogPawFilled: dogPawSVGFilled,
  eColiFilled: eColiSVGFilled,
  edit01Filled: edit01SVGFilled,
  edit02Filled: edit02SVGFilled,
  enterococcusFilled: enterococcusSVGFilled,
  eyeOffFilled: eyeOffSVGFilled,
  eyeFilled: eyeSVGFilled,
  faceContentFilled: faceContentSVGFilled,
  faceFrownFilled: faceFrownSVGFilled,
  faceHappyFilled: faceHappySVGFilled,
  faceMaskFilled: faceMaskSVGFilled,
  faceNeutralFilled: faceNeutralSVGFilled,
  faceSadFilled: faceSadSVGFilled,
  faceSmileFilled: faceSmileSVGFilled,
  faceWinkFilled: faceWinkSVGFilled,
  feedingBabyFilled: feedingBabySVGFilled,
  fermentedFilled: fermentedSVGFilled,
  file01Filled: file01SVGFilled,
  file02Filled: file02SVGFilled,
  file03Filled: file03SVGFilled,
  file04Filled: file04SVGFilled,
  file05Filled: file05SVGFilled,
  file06Filled: file06SVGFilled,
  fileAttachmentFilled: fileAttachmentSVGFilled,
  fileCheck01Filled: fileCheck01SVGFilled,
  fileCheck02Filled: fileCheck02SVGFilled,
  fileHeart01Filled: fileHeart01SVGFilled,
  fileHeart02Filled: fileHeart02SVGFilled,
  filePlus01Filled: filePlus01SVGFilled,
  filePlus02Filled: filePlus02SVGFilled,
  fileQuestion01Filled: fileQuestion01SVGFilled,
  fileQuestion02Filled: fileQuestion02SVGFilled,
  fileQuestion03Filled: fileQuestion03SVGFilled,
  fileSearch01Filled: fileSearch01SVGFilled,
  fileSearch02Filled: fileSearch02SVGFilled,
  flagFilled: flagSVGFilled,
  flashFilled: flashSVGFilled,
  flaxSeedsFilled: flaxSeedsSVGFilled,
  giftFilled: giftSVGFilled,
  heartHealthFilled: heartHealthSVGFilled,
  heartsFilled: heartsSVGFilled,
  helpFilled: helpSVGFilled,
  highRiskFilled: highRiskSVGFilled,
  historyFilled: historySVGFilled,
  homeFilled: homeSVGFilled,
  horizontalBarChartFilled: horizontalBarChartSVGFilled,
  housekeepingFilled: housekeepingSVGFilled,
  infoCircleFilled: infoCircleSVGFilled,
  intestineFilled: intestineSVGFilled,
  lightOnFilled: lightOnSVGFilled,
  lineChartUp01Filled: lineChartUp01SVGFilled,
  lineChartUp02Filled: lineChartUp02SVGFilled,
  lineChartUpFilled: lineChartUpSVGFilled,
  loadingFilled: loadingSVGFilled,
  lockFilled: lockSVGFilled,
  logOutFilled: logOutSVGFilled,
  lotusFilled: lotusSVGFilled,
  magicWandFilled: magicWandSVGFilled,
  mailFilled: mailSVGFilled,
  markerPin01Filled: markerPin01SVGFilled,
  markerPin02Filled: markerPin02SVGFilled,
  microbiomeFilled: microbiomeSVGFilled,
  microscopeFilled: microscopeSVGFilled,
  mortarAndPestleFilled: mortarAndPestleSVGFilled,
  muscleFilled: muscleSVGFilled,
  nappyFilled: nappySVGFilled,
  naturalFoodFilled: naturalFoodSVGFilled,
  notificationsFilled: notificationsSVGFilled,
  nutFilled: nutSVGFilled,
  organicFoodFilled: organicFoodSVGFilled,
  pacifierFilled: pacifierSVGFilled,
  paperclipFilled: paperclipSVGFilled,
  pieChart01Filled: pieChart01SVGFilled,
  pieChart02Filled: pieChart02SVGFilled,
  pieChart03Filled: pieChart03SVGFilled,
  pieChart04Filled: pieChart04SVGFilled,
  pillFilled: pillSVGFilled,
  pillsFilled: pillsSVGFilled,
  pregnantFilled: pregnantSVGFilled,
  prenatalYogaFilled: prenatalYogaSVGFilled,
  priceTagFilled: priceTagSVGFilled,
  printerFilled: printerSVGFilled,
  repeatFilled: repeatSVGFilled,
  sadGhostFilled: sadGhostSVGFilled,
  scaleFilled: scaleSVGFilled,
  seedingFilled: seedingSVGFilled,
  sendFilled: sendSVGFilled,
  settingsFilled: settingsSVGFilled,
  sheepFilled: sheepSVGFilled,
  starFilled: starSVGFilled,
  stars01Filled: stars01SVGFilled,
  stars02Filled: stars02SVGFilled,
  stars03Filled: stars03SVGFilled,
  stethoscopeFilled: stethoscopeSVGFilled,
  stickerCircleFilled: stickerCircleSVGFilled,
  stickerSquareFilled: stickerSquareSVGFilled,
  stomachFilled: stomachSVGFilled,
  swabFilled: swabSVGFilled,
  syringeFilled: syringeSVGFilled,
  targetFilled: targetSVGFilled,
  todayFilled: todaySVGFilled,
  toolFilled: toolSVGFilled,
  trashFilled: trashSVGFilled,
  trendDownFilled: trendDownSVGFilled,
  trendUp01Filled: trendUp01SVGFilled,
  trendUp02Filled: trendUp02SVGFilled,
  trophyFilled: trophySVGFilled,
  userCheckFilled: userCheckSVGFilled,
  userEditFilled: userEditSVGFilled,
  userMultipleFilled: userMultipleSVGFilled,
  userSingleFilled: userSingleSVGFilled,
  virusFilled: virusSVGFilled,
  zapCircleFilled: zapCircleSVGFilled,
  zapSquareFilled: zapSquareSVGFilled,
};

export const getSvgByName = name => {
  return ICON_SVG[name] ? ICON_SVG[name] : ICON_SVG['microbiomeFilled'];
};
