import produce from 'immer';

const admin = set => ({
  admin: {
    admin: undefined, // undefined is default, null is for when admin is known not to exist,
    token: undefined, // undefined is default
    setAdmin: (admin, token) => {
      set(
        produce(state => {
          state.admin.admin = admin;
          state.admin.token = token;
        }),
      );
    },
  },
});

export default admin;
