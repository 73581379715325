import PageListView from '../../components/Page/PageListView';
import { formatDate } from '../../lib/utils/formatDate';

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'First name',
    accessor: 'first_name',
  },
  {
    Header: 'Last name',
    accessor: 'last_name',
  },
  {
    Header: 'Created at',
    id: 'created_at',
    accessor: row => formatDate(row.created_at),
  },
];

export default function TinyAccounts () {
  return (
    <PageListView
      header='Tiny Accounts'
      tableProps={{
        type: 'tinyaccounts',
        route: 'tinyaccounts',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
