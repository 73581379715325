import React, { useState } from 'react';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

export default function UnusedKit({ unused_kit, row_index, removeRow }) {
  const [error, setError] = useState();
  const [params, setParams] = useState({});
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);

  const updateUnusedKit = async (
    id,
    tracking_to_lab_microbiome,
    tracking_to_lab_stoolchemistry,
  ) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/unused-kits/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracking_to_lab_microbiome,
        tracking_to_lab_stoolchemistry,
      }),
    });
    if (res.status === 200) {
      setParams({
        tracking_to_lab_microbiome: null,
        tracking_to_lab_stoolchemistry: null,
      });
      removeRow(row_index);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem updating the unused kit, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error);
    }
  };

  return (
    <>
      <div className='flex w-full break-words py-4 px-2 text-left border-t border-solid border-gray-300'>
        <span className='text-sm w-1/5'>{unused_kit.id}</span>
        <span className='text-sm w-full'>
          <input
            disabled={unused_kit.tracking_to_lab_microbiome}
            value={params.tracking_to_lab_microbiome || ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                tracking_to_lab_microbiome: e.target.value,
              }))
            }
            className='flex-auto w-full relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
          />
        </span>
        <span className='text-sm w-full'>
          <input
            placeholder='optional'
            value={params.tracking_to_lab_stoolchemistry || ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                tracking_to_lab_stoolchemistry: e.target.value,
              }))
            }
            className='flex-auto w-full relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
          />
        </span>

        <div>
          <button
            onClick={() =>
              updateUnusedKit(
                unused_kit.id,
                params.tracking_to_lab_microbiome,
                params.tracking_to_lab_stoolchemistry,
              )
            }
            className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
            disabled={!params.tracking_to_lab_microbiome}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
