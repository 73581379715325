import { useMemo } from 'react';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';

export default function PartnerDropdown({ defaultValue, onChange, partners }) {
  const selectOptions = useMemo(() => {
    if (partners?.length) {
      const partnerOptions = partners.map(partner => {
        return {
          label: partner.name,
          value: partner.id,
        };
      });
      return partnerOptions;
    }
    return [];
  }, [partners]);

  return (
    <SearchableDropdown
      defaultValue={defaultValue}
      onChange={onChange}
      selectOptions={selectOptions}
      searchKeys={['label']}
    />
  );
}
