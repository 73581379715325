const TableContainer = ({ children }) => {
  return (
    <div className='my-6 shadow-md'>
      <div className='flex flex-wrap justify-center items-start'>
        <div className='max-w-full min-w-1/2 flex-auto'>{children}</div>
      </div>
    </div>
  );
};

export default TableContainer;
