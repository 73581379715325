import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Display Title',
    accessor: 'display_title',
  },
];

export default function Recommendations() {
  return (
    <PageListView
      header='Recommendations'
      rightButton={{
        title: 'Create New',
        url: '/recommendations/new',
      }}
      tableProps={{
        type: 'recommendations',
        route: 'recommendations',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Name',
            filter: 'name',
          },
          {
            label: 'Display Title',
            filter: 'display_title',
          },
        ],
      }}
    />
  );
}
