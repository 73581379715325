import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

export default function ConsultCard({ consult }) {
  return (
    <div className='first:mt-0 mt-4' key={consult.id}>
      <div className='flex-1 max-w-full break-all'>
        <div className='flex flex-col relative'>
          <NavLink
            to={`/consults/${consult.id}`}
            className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
          >
            <h6 className='font-bold text-base'>
              {consult.scheduled_dt
                ? moment
                    .unix(consult.scheduled_dt)
                    .tz('US/Central')
                    .format('YYYY-MM-DD hh:mm A')
                : ''}
            </h6>
            {consult.type && (
              <span className='font-semibold text-sm mt-1'>{consult.type}</span>
            )}
            {consult.agent && <p className='text-sm mt-1'>{consult.agent}</p>}
            <div className='flex items-center mt-2'>
              {!consult.status ? null : consult.status === 'cancelled' ? (
                <span className='text-sm rounded-2xl p-2 bg-red-100 '>
                  {consult.status}
                </span>
              ) : consult.status.startsWith('completed') ? (
                <span className='text-sm rounded-2xl p-2 bg-green-100 '>
                  {consult.status}
                </span>
              ) : consult.status === 'scheduled' ? (
                <span className='text-sm rounded-2xl p-2 bg-purple-100 '>
                  {consult.status}
                </span>
              ) : (
                <span className='text-sm rounded-2xl p-2 bg-yellow-100 '>
                  {consult.status}
                </span>
              )}
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
