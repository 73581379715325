import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';

export default function ResetPassword () {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const resetPassword = async e => {
    e.preventDefault();
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/reset-password/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      setSuccess(true);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem processing your request, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error ? error : 'Something went wrong, please try again.');
    }
  };
  if (success) {
    return (
      <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
        <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
          <div className='flex flex-col max-w-lg justify-center'>
            <h1 className='mb-4 mx-0 text-4xl font-serif'>
              Reset your password
            </h1>
            <span className='text-sm mb-4'>
              We sent a password reset link to{' '}
              <span className='text-sm'>{email}</span> if there was a Tiny Ops
              account associated with it. Please allow up to 5 minutes for the
              email to arrive.
            </span>
            <span className='text-sm'>
              If you are unable to access your account, please contact{' '}
              <a
                href='mailto:donald@tinyhealth.com'
                className='text-sm text-green-300 underline hover:text-green-400'
              >
                donald@tinyhealth.com
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
      <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
        <div className='flex flex-col max-w-lg justify-center'>
          <h1 className='mb-5 mx-0 text-4xl font-serif'>Reset your password</h1>
          <form autoComplete='on'>
            <div className='grid grid-cols-1 gap-6 grid-flow-row'>
              <div className='flex relative'>
                <label htmlFor='Email' />
                <input
                  aria-label='Email'
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setError(null);
                  }}
                  className='w-full h-12 py-3 px-4 text-sm bg-white rounded-md leading-6 outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                />
              </div>
            </div>
            <div className='my-4 text-red-300 text-xs'>{error}</div>
            <button
              onClick={e => resetPassword(e)}
              className='text-sm bg-green-300 text-white m-0 py-3 px-4 rounded-md leading-5 inline-block items-start text-center transition-all duration-200 ease-in-out delay-75 hover:bg-green-400'
            >
              Continue
            </button>
            <div className='mt-4 w-full flex flex-row text-sm'>
              Login instead?{' '}
              <NavLink
                to='/login'
                className='text-green-300 underline hover:text-green-400 ml-1 text-sm'
              >
                Go back
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
