import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'Display Title', accessor: 'display_title' },
  { Header: 'Age low', accessor: 'age_low' },
  { Header: 'Age high', accessor: 'age_high' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Checkpoints', accessor: 'checkpoints' },
];

export default function Conditions () {
  return (
    <PageListView
      header='Conditions'
      tableProps={{
        type: 'conditions',
        route: 'conditions',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
