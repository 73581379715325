import React from 'react';
import { NavLink } from 'react-router-dom';

export default function E404 () {
  return (
    <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
      <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
        <div className='flex flex-col max-w-lg justify-center'>
          <h1 className='m-0 text-4xl font-serif'>Uh oh...</h1>
          <div className='mb-5 mt-3 w-full flex flex-row'>
            It seems like someone tripped over a cable... or the page you’re
            looking for doesn’t exist.
          </div>
          <div className='w-full m-0 flex'>
            <NavLink
              className='flex flex-col justify-center relative cursor-pointer text-green-300 underline hover:text-green-400'
              to='/'
            >
              &#8592; Back
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
