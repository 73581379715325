import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Age Low', accessor: 'age_low' },
  { Header: 'Age High', accessor: 'age_high' },
  { Header: 'Sampling Type', accessor: 'sampling_type' },
  { Header: 'Category Key', accessor: 'category_key' },
  { Header: 'Published', accessor: 'published' },
];

export default function KeyInsights () {
  return (
    <PageListView
      header='Key Insights'
      tableProps={{
        type: 'keyInsights',
        route: 'keyInsights',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Name',
            filter: 'name',
          },
          {
            label: 'Category Key',
            filter: 'category_key',
          },
          {
            label: 'Sampling Type',
            filter: 'sampling_type',
          },
        ],
      }}
    />
  );
}
