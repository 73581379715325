import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import updateLocalToken from '../../lib/updateLocalToken';

export default function Login () {
  const history = useHistory();
  const setAdmin = useStore(state => state.admin.setAdmin);
  const setRoute = useStore(state => state.route.setRoute);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const login = async e => {
    e.preventDefault();
    const params = {
      email,
      password,
    };
    const res = await fetch(`${API_BASE_PATH}/ops/admins/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (res.status === 200) {
      const { admin, token } = await res.json();
      updateLocalToken(token);
      await setAdmin(admin, token);
      await setRoute('kits');
      history.push('/kits');
    } else if (res.status === 500 || res.status === 404) {
      setError(
        `There was a problem processing your request, please try again later or contact support.`,
      );
    } else {
      const { message } = await res.json();
      setError(message ? message : `Something went wrong, please try again.`);
    }
  };
  return (
    <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
      <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
        <div className='flex flex-col max-w-lg justify-center'>
          <h1 className='m-0 text-4xl font-serif'>Welcome to Tiny Ops</h1>
          <div className='mb-5 mt-3 w-full flex flex-row text-sm'>
            {`Don't have an account? `}
            <NavLink
              to='/signup'
              className='text-sm underline text-green-300 ml-1 hover:text-green-400'
            >
              Click here
            </NavLink>
          </div>
          <form autoComplete='on'>
            <div className='grid grid-cols-1 gap-4 grid-flow-row'>
              <div className='flex relative'>
                <label htmlFor='Email' />
                <input
                  aria-label='Email'
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setError(null);
                  }}
                  className='w-full h-12 py-3 px-4 bg-white rounded-md leading-6 text-sm outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                />
              </div>
              <div className='flex relative'>
                <label htmlFor='Password' />
                <input
                  aria-label='Password'
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setError(null);
                  }}
                  className='w-full h-12 py-3 px-4 bg-white rounded-md leading-6 text-sm outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                />
              </div>
            </div>
            <div className='my-4 text-red-300 text-xs'>{error}</div>
            <button
              onClick={e => login(e)}
              className='text-sm bg-green-300 text-white m-0 py-3 px-4 rounded-md leading-5 inline-block items-start text-center transition-all duration-200 ease-in-out delay-75 hover:bg-green-400'
            >
              Login
            </button>
            <div className='mt-4 w-full flex flex-row text-sm'>
              Forgot your password?{' '}
              <NavLink
                to='/reset-password'
                className='text-sm underline text-green-300 ml-1 hover:text-green:400'
              >
                Click here
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
