import React from 'react';

export default function CheckboxInput ({
  title,
  subtitle,
  checked,
  onChange,
}: {
  title: string;
  subtitle?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div className='mt-4 flex-1 max-w-full'>
      <div className='flex'>
        <input
          className='cursor-pointer w-4'
          type='checkbox'
          checked={checked || false}
          onChange={onChange}
        />
        <label className='ml-2 pt-1 text-sm'>
          {title}
          <label className='pl-1 text-xs opacity-60'>{subtitle}</label>
        </label>
      </div>
    </div>
  );
}
