import { useState } from 'react';
import c from 'classnames';
import { formatDate } from '../../lib/utils/formatDate';
import { getStatusColor } from '../Practitioners';
import Table from '../../components/Table';
import PageContainer from '../../components/Page/PageContainer';
import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

const PractitionerInfo = ({ practitioner, action }) => {
  return (
    <div className='flex flex-col w-full bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 py-3 px-4'>
      <div className='flex flex-auto whitespace-nowrap'>
        <h2 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
          Selected practitioner
        </h2>
        <div className='ml-2 lg:ml-4 mt-0 flex'>
          <button
            onClick={() => action()}
            className='flex justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
          >
            Change
          </button>
        </div>
      </div>
      <div className='flex flex-col gap-2 mt-4'>
        <div className='font-medium'>{`Email: ${practitioner['Email']}`}</div>
        <div className='font-medium'>{`Full name: ${practitioner['First name']} ${practitioner['Last name']}`}</div>
        <div className='font-medium'>{`Organization: ${practitioner['Organization']}`}</div>
        <div className='font-medium'>{`Created at: ${practitioner['Created at']}`}</div>
      </div>
    </div>
  );
};

const AccountInfo = ({ account, action }) => {
  return (
    <div className='flex flex-col w-full bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 py-3 px-4'>
      <div className='flex flex-auto whitespace-nowrap'>
        <h2 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
          Selected account
        </h2>
        <div className='ml-2 lg:ml-4 mt-0 flex'>
          <button
            onClick={() => action()}
            className='flex justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
          >
            Change
          </button>
        </div>
      </div>
      <div className='flex flex-col gap-2 mt-4'>
        <div className='font-medium'>{`Email: ${account['email']}`}</div>
        <div className='font-medium'>{`Full name: ${account['first_name']} ${account['last_name']}`}</div>
        <div className='font-medium'>{`Created at: ${account['created_at']}`}</div>
      </div>
    </div>
  );
};

const practitionerColumns = [
  { Header: 'Email', accessor: row => row.user?.email },
  { Header: 'First name', accessor: row => row.user?.first_name },
  { Header: 'Last name', accessor: row => row.user?.last_name },
  { Header: 'Organization', accessor: row => row.organization },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getStatusColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Created at',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Action',
    accessor: 'id',
    Cell: ({ value }) => (
      <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
        Select
      </button>
    ),
  },
];
const accountColumns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'First name',
    accessor: 'first_name',
  },
  {
    Header: 'Last name',
    accessor: 'last_name',
  },
  {
    Header: 'Created at',
    id: 'created_at',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Action',
    Cell: ({ value }) => (
      <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
        Select
      </button>
    ),
  },
];

export default function PractitionerSwap() {
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [practitioner, setPractitioner] = useState(null);
  const [mainAccount, setMainAccount] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const swapEmails = async (practitionerEmail, accountEmail) => {
    setIsSubmitting(true);
    setErrors([]);
    setUpdated(false);
    const updateData = {
      practitionerEmail,
      accountEmail,
    };
    const res = await fetch(`${API_BASE_PATH}/ops/admins/swap-practitioner`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      setUpdated(true);
      setPractitioner(null);
      setMainAccount(null);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
    setIsSubmitting(false);
  };

  return (
    <PageContainer>
      <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
        <div className='relative flex-auto mx-auto my-0 py-0 px-2 max-w-6xl w-full'>
          <div className='pt-4 lg:pt-6 px-0 relative w-full'>
            <h1 className='font-serif text-2xl break-word leading-8 m-0 font-semibold'>
              Swap practitioner emails
            </h1>
            <p className='font-medium my-2'>
              This tool allows to swap the emails used in a practitioner account
              and a normal account. These should belong to the same person.
            </p>
            {updated && (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 my-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>Emails successfully swapped</div>
                </div>
              </div>
            )}
            <div className='flex flex-col gap-4 w-full'>
              {!practitioner ? (
                <Table
                  type='practitioners'
                  route='practitioners'
                  columns={practitionerColumns}
                  searchable={true}
                  onRowClick={row => setPractitioner(row.values)}
                />
              ) : (
                <PractitionerInfo
                  practitioner={practitioner}
                  action={setPractitioner}
                />
              )}
              {!!practitioner && (
                <div>
                  {!mainAccount ? (
                    <Table
                      type='mainaccounts'
                      route='mainaccounts'
                      columns={accountColumns}
                      searchable={true}
                      onRowClick={row => setMainAccount(row.values)}
                    />
                  ) : (
                    <>
                      <AccountInfo
                        account={mainAccount}
                        action={setMainAccount}
                      />

                      <button
                        onClick={() =>
                          swapEmails(practitioner.Email, mainAccount.email)
                        }
                        disabled={isSubmitting}
                        className='flex justify-center items-center relative overflow-hidden my-4 p-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                      >
                        SWAP EMAILS
                      </button>
                      {errors && errors.length > 0 ? (
                        <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                          <div className='flex flex-col p-3'>
                            <div className='font-semibold'>
                              There{' '}
                              {errors.length === 1
                                ? 'is 1 error'
                                : `are ${errors.length} errors`}{' '}
                              with this practitioner:
                            </div>
                            <div className='ml-4 pl-4'>
                              <ul className='list-disc'>
                                {errors.map((error, idx) => (
                                  <li className='mt-2' key={idx}>
                                    {error}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
