import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Published',
    accessor: 'published',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
];

export default function RecommendationItems () {
  return (
    <PageListView
      header='Recommendation Items'
      rightButton={{
        title: 'Create New',
        url: '/recommendationItems/new',
      }}
      tableProps={{
        type: 'recommendationItems',
        route: 'recommendationItems',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Title',
            filter: 'title',
          },
          {
            label: 'Type',
            filter: 'type',
          },
          {
            label: 'Description',
            filter: 'description',
          },
        ],
      }}
    />
  );
}
