import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

export default function Breadcrumbs({ items }) {
  if (!items?.length) return <></>;
  return (
    <nav className='bg-grey-light rounded-md w-full'>
      <ol className='list-reset flex'>
        {items.map((item, idx) => (
          <Fragment key={'bc_item_' + idx}>
            {item.link ? (
              <li>
                <NavLink
                  to={item.link}
                  className='text-blue-600 hover:text-blue-700'
                >
                  {item.display}
                </NavLink>
              </li>
            ) : (
              <li className='text-gray-500' key={'bc_item_' + idx}>
                {item.display}
              </li>
            )}
            {idx < items.length - 1 && (
              <li>
                <span className='text-gray-500 font-xs mx-2 h-1'>{'>'}</span>
              </li>
            )}
          </Fragment>
        ))}
      </ol>
    </nav>
  );
}
