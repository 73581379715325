export const queryBuilder = ({
  page,
  pageLimit,
  filterType,
  filterValue,
  orderBy,
  order,
  searchTerm,
  searchTermFilter,
}: {
  page: number;
  pageLimit?: number;
  filterType: string;
  filterValue: string;
  orderBy: string;
  order: string;
  searchTerm?: string;
  searchTermFilter?: string;
}) => {
  const query = new URLSearchParams('');
  if (page >= 0) {
    query.append('page', page.toString());
  }

  if (pageLimit != null && pageLimit != undefined) {
    query.append('pageLimit', pageLimit.toString());
  }

  if (filterType && filterValue && filterValue !== 'all') {
    query.append('filterType', filterType);
    query.append('filterValue', filterValue);
  }

  if (orderBy) {
    query.append('orderBy', orderBy);
    if (order) {
      query.append('order', order);
    }
  }

  if (searchTerm) {
    query.append('searchTerm', searchTerm);
  }

  if (searchTermFilter) {
    query.append('searchTermFilter', searchTermFilter);
  }

  return query;
};
