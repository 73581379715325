import React from 'react';
import Loader from 'react-spinners/HashLoader';

import Logo from '../../assets/logo/logo.svg';

export default function Loading () {
  return (
    <div className='fixed w-screen m-0 p-4 border-0 min-h-screen z-50'>
      <div className='flex flex-col p-2'>
        <div className='flex max-w-xs'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Health' />
          </div>
        </div>
      </div>
      <div className='my-10 mx-auto'>
        <div className='flex justify-center'>
          <Loader color='#009b87' size='75px' speedMultiplier='0.75' />
        </div>
      </div>
    </div>
  );
}
