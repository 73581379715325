import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Display Title', accessor: 'display_title' },
];

export default function KeyInsightsSections () {
  return (
    <PageListView
      header='Key Insights Sections'
      tableProps={{
        type: 'keyInsightsSections',
        route: 'keyInsightsSections',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
