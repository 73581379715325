import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function Macro() {
  const route = 'macros';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'macro');
  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      macro_keyword: params.macro_keyword,
      description: params.description,
      macro_text: params.macro_text,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  return (
    <PageDetailView
      header={`Macro ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Macro Keyword'
            placeholder='Macro Keyword'
            value={params.macro_keyword}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                macro_keyword: e.target.value.replace(/[^a-zA-Z_]/g, ''),
              }))
            }
          />

          <TextInput
            title='Macro Description (Internal)'
            placeholder='Macro Description'
            type='text'
            value={params.description}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                description: e.target.value,
              }))
            }
          />

          <TextInput
            title='Macro Text'
            placeholder='Macro Text'
            type='textarea'
            value={params.macro_text}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                macro_text: e.target.value,
              }))
            }
            className='h-[15rem]'
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
