import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from 'components/CheckboxInput/CheckboxInput';
import SelectInput from 'components/SelectInput/SelectInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function ShopifyProduct() {
  const route = 'shopifyProducts';

  const history = useHistory();
  const { id, variant_id } = useParams<{ id: string; variant_id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(
    route,
    id === 'new' ? id : `${id}/${variant_id}`,
    'shopifyProduct',
  );

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id, variant_id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      id: params.id,
      variant_id: params.variant_id,
      name: params.name,
      lookup_code: params.lookup_code,
      type: params.type,
      fulfillment_interval:
        params.type === 'intensive_program' ? params.fulfillment_interval : 0,
      num_kits_gut: params.num_kits_gut,
      num_kits_vaginal: params.num_kits_vaginal,
      num_kits_stool_chemistry: params.num_kits_stool_chemistry,
      consult_credits: params.consult_credits,
      fulfill: params.fulfill,
      is_expedited: params.is_expedited,
      is_program: params.is_program,
      is_research: params.is_research,
      calendly_link: params.calendly_link,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}/${updatedObj.variant_id}`);
      } else {
        await fetchData();
      }
    }
  };

  return (
    <PageDetailView
      header={`Shopify Product ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='ID'
            placeholder='ID'
            type='number'
            value={params.id}
            disabled={id !== 'new'}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                id: e.target.value,
              }))
            }
          />

          <TextInput
            title='Variant ID (from Shopify)'
            placeholder='Variant ID'
            type='number'
            value={params.variant_id}
            disabled={true}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                variant_id: e.target.value,
              }))
            }
          />

          <TextInput
            title='Name'
            placeholder='Name'
            value={params.name}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />

          <TextInput
            title='Lookup Code'
            placeholder='Lookup Code'
            subtitle='Unique identifier for the product. (ex: GUT_TEST_PROGRAM)'
            value={params.lookup_code}
            disabled={id !== 'new'}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                lookup_code: e.target.value
                  .trim()
                  .toUpperCase()
                  .replaceAll(' ', '_'),
              }))
            }
          />

          <SelectInput
            title='Type (if applicable)'
            options={[
              { value: '', title: '-- Choose a type --' },
              { value: 'annual_membership', title: 'Annual Membership' },
              { value: 'baseline_assessment', title: 'Baseline Assessment' },
              {
                value: 'intensive_program',
                title: 'Intensive Program',
              },
            ]}
            value={params.type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                type: e.target.value,
              }))
            }
          />

          {params.type === 'intensive_program' && (
            <TextInput
              title='Fulfillment Interval'
              placeholder='Fulfillment Interval'
              subtitle='How often should the intensive program products be fulfilled? (in months)'
              type='number'
              value={params.fulfillment_interval}
              onChange={e =>
                setParams(prevState => ({
                  ...prevState,
                  fulfillment_interval: e.target.value,
                }))
              }
            />
          )}

          <TextInput
            title='Num Kits Gut'
            placeholder='Num Kits Gut'
            type='number'
            value={params.num_kits_gut}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                num_kits_gut: e.target.value,
              }))
            }
          />

          <TextInput
            title='Num Kits Vaginal'
            placeholder='Num Kits Vaginal'
            type='number'
            value={params.num_kits_vaginal}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                num_kits_vaginal: e.target.value,
              }))
            }
          />

          <TextInput
            title='Num Kits Stool Chemistry'
            placeholder='Num Stool Chemistry'
            type='number'
            value={params.num_kits_stool_chemistry}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                num_kits_stool_chemistry: e.target.value,
              }))
            }
          />

          <TextInput
            title='Num Consult Credits'
            placeholder='Num Consult Credits'
            type='number'
            value={params.consult_credits}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                consult_credits: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Fulfill'
            subtitle='(Should fulfill kits from Shopify)'
            checked={params.fulfill}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                fulfill: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Is Expedited'
            subtitle='(Should be marked as expedited fulfillment)'
            checked={params.is_expedited}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                is_expedited: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Is Program'
            subtitle='(Should be marked as a program)'
            checked={params.is_program}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                is_program: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Is Research'
            subtitle='(Should be marked as research in Klaviyo)'
            checked={params.is_research}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                is_research: Number(e.target.checked),
              }))
            }
          />

          <TextInput
            title='Calendly Link'
            placeholder='Calendly Link'
            value={params.calendly_link}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                calendly_link: e.target.value,
              }))
            }
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
