import { useState, useMemo } from 'react';
import ReactSelect from 'react-select';
import { matchSorter } from 'match-sorter';

import './styles.css';

export default function SearchableDropdown({
  defaultValue,
  onChange,
  selectOptions,
  searchKeys = ['label'],
}) {
  const [inputValue, setInputValue] = useState('');

  const filteredOptions = useMemo(() => {
    if (inputValue) {
      const sorted = matchSorter(selectOptions, inputValue, {
        keys: searchKeys,
      });
      return sorted;
    }
    return selectOptions;
  }, [inputValue, selectOptions]);

  return (
    <ReactSelect
      options={filteredOptions}
      onChange={onChange}
      defaultValue={selectOptions.find(
        option => option?.value === defaultValue,
      )}
      isSearchable={true}
      isClearable={true}
      components={{
        Option: ({ children, ...props }) => (
          <div {...props} onClick={props.innerProps.onClick}>
            <div className='selectOption'>
              {children}
              <p className='mb-0 f7'>{props.data.email}</p>
            </div>
          </div>
        ),
      }}
      filterOption={() => true}
      onInputChange={inputValue => setInputValue(inputValue)}
    />
  );
}
