import React from 'react';

import Logo from '../../assets/logo/logo.svg';

export default function UnderConstruction () {
  return (
    <div className='fixed w-screen m-0 p-4 border-0 min-h-screen z-50'>
      <div className='flex flex-col p-2'>
        <div className='flex max-w-xs'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Health' />
          </div>
        </div>
      </div>
      <div className='my-4 mx-auto'>
        <div className='flex lg:ml-6'>
          <span>This page is under construction. Check back later!</span>
        </div>
      </div>
    </div>
  );
}
