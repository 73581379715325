const removeVPrefix = version => {
  return typeof version === 'string' ? version.replace(/^v/, '') : '';
};
const removeLeadingZeros = version => {
  //removes leading zeroes but keeps the last zero if it is the only digit
  return typeof version === 'string' ? version.replace(/^0+(?=\d)/, '') : '';
};

export const VersionPill = ({ version, latestSciVersion }) => {
  const displayVersion = version
    ? removeVPrefix(version)
        .split('.')
        .map(segment => removeLeadingZeros(segment))
        .join('.')
    : latestSciVersion;
  const isUpdating = version === null;
  return (
    <div className='rounded-full p-1 px-2 gap-1 shadow-sm flex items-center justify-around bg-gray-100'>
      <div
        className={`h-2 w-2 rounded-full inline-block ${
          isUpdating ? 'bg-orange-200' : 'bg-green-200'
        }`}
      />
      <div className='text-xs pt-1'>
        {isUpdating ? `V updating` : `V ${displayVersion}`}
      </div>
    </div>
  );
};
