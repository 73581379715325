import c from 'classnames';
import { NavLink } from 'react-router-dom';

import Table from '../../components/Table';
import PageContainer from '../../components/Page/PageContainer';
import PageHeader from '../../components/Page/PageHeader';

const mediaTableColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Type', accessor: 'type' },
];

export default function MediaUploads() {
  return (
    <PageContainer>
      <div className='flex justify-between'>
        <PageHeader title={'Media Uploads'} />
        <NavLink to='/mediaUploads/new'>
          <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
            Create New
          </button>
        </NavLink>
      </div>

      <Table
        type={'mediaUploads'}
        route={'mediaUploads'}
        columns={mediaTableColumns}
        searchable
      />
    </PageContainer>
  );
}
