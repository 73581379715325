import PageListView from '../../components/Page/PageListView';
import { formatDate } from '../../lib/utils/formatDate';

const columns = [
  {
    Header: 'Admin',
    accessor: row =>
      row.admin
        ? `${row.admin.first_name} ${row.admin.last_name}`
        : row.admin_id,
  },
  {
    Header: 'Table',
    accessor: 'updated_table',
  },
  {
    Header: 'ID',
    accessor: 'updated_id',
  },
  {
    Header: 'Created At',
    accessor: row => formatDate(row.created_at),
  },
];

export default function AdminLogs () {
  return (
    <PageListView
      header='Update Logs'
      tableProps={{
        type: 'logs',
        route: 'logs',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
