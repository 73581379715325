import AddPiecePlaceholder from '../../assets/iconography/add_piece_placeholder.svg';

const ErrorDisplay = ({ error }) => {
  return (
    <div className='my-6 shadow-md'>
      <div className='flex flex-wrap justify-center items-start'>
        <div className='max-w-full min-w-1/2 flex-auto'>
          <div className='bg-white rounded-md w-full'>
            <div className='flex flex-col p-8 items-center'>
              <div className='flex flex-col items-center p-8 pb-24 my-0 mx-auto max-w-lg'>
                <div className='flex flex-col md:flex-col-reverse flex-auto'>
                  <div className='flex-auto p-0 m-0 '>
                    <div className='flex flex-col items-center text-center'>
                      <div className='text-2xl font-serif m-0'>
                        Something went wrong!
                      </div>
                      <div className='mt-2 text-sm font-thin pb-3'>{error}</div>
                    </div>
                  </div>
                  <div className='flex-auto p-0 m-0'>
                    <div className='flex flex-col items-center text-center'>
                      <img
                        src={AddPiecePlaceholder}
                        alt='Tiny ops'
                        className='m-0 w-auto'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorDisplay;
