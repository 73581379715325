import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';

import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomInput from '../../components/CustomInput/CustomInput';

import { Icon } from '../../components/Icon/Icon';
import { ICON_SVG } from '../../helpers';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function KeyInsightsCategory () {
  const route = 'keyInsightsCategory';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, route);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      name: params.name,
      beta: params.beta,
      published: params.published,
      age_group: params.age_group,
      age_low: params.age_low,
      age_high: params.age_high,
      category_description: params.category_description,
      category_title: params.category_title,
      icon: params.icon,
      sampling_state: params.sampling_state,
      sampling_type: params.sampling_type,
      section_order: params.section_order,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  const iconList = [];
  const icon_names = Object.keys(ICON_SVG).sort();
  for (const icon_name of icon_names) {
    iconList.push({
      value: icon_name,
      label: (
        <div className='flex'>
          <Icon name={icon_name} size='s' />
          <span className='ml-2 text-sm'>{icon_name}</span>
        </div>
      ),
    });
  }

  return (
    <PageDetailView
      header={`Key Insights Category ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Category Name'
            placeholder='Category Name'
            value={params.name}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Published'
            checked={params.published}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                published: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Beta'
            checked={params.beta}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                beta: Number(e.target.checked),
              }))
            }
          />

          <TextInput
            title='Title'
            placeholder='Title'
            value={params.category_title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                category_title: e.target.value,
              }))
            }
          />

          <SelectInput
            title='Age Group'
            options={[
              { value: '', title: '-- Choose a group --', disabled: true },
              { value: 'baby', title: 'Baby' },
              { value: 'toddler', title: 'Toddler' },
              { value: 'child', title: 'Child' },
              { value: 'adult', title: 'Adult' },
            ]}
            value={params.age_group ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_group: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age Low'
            placeholder='Age Range Low'
            value={params.age_low}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_low: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age High'
            placeholder='Age Range High'
            value={params.age_high}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_high: e.target.value,
              }))
            }
          />

          <SelectInput
            title='Sampling Type'
            options={[
              { value: '', title: '-- Choose a type --', disabled: true },
              { value: 'stool', title: 'Stool' },
              { value: 'vaginal', title: 'Vaginal' },
            ]}
            value={params.sampling_type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                sampling_type: e.target.value,
              }))
            }
          />

          <TextInput
            title='Sampling State'
            placeholder='Sampling State'
            value={params.sampling_state}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                sampling_state: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Section Order'
            placeholder='Section Order'
            value={params.section_order}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                section_order: e.target.value,
              }))
            }
          />

          <CustomInput title='Icon'>
            <Select
              className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
              classNamePrefix='select'
              value={iconList.find(obj => obj.value === params.icon)}
              isClearable={true}
              isSearchable={true}
              name='icon'
              placeholder='Icon'
              options={iconList}
              onChange={e =>
                setParams(prevState => ({
                  ...prevState,
                  icon: e?.value,
                }))
              }
            />
          </CustomInput>
        </PageDetailCard>
        <PageDetailCard column={1} title={'Description'}>
          <MarkdownEditor
            height={300}
            value={params.category_description}
            onChange={(value: string) => {
              setParams(prevState => ({
                ...prevState,
                category_description: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
