import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';

import moment from 'moment';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from 'lib/constants';

export default function KeyInsightsSection() {
  const route = 'keyInsightsSections';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'keyInsightsSection');
  const [mediaUploads, setMediaUploads] = useState<
    { id: number; name: string; url: string; updated_at: string }[]
  >([]);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
    getOtherData();
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      name: params.name,
      display_title: params.display_title,
      short_description: params.short_description,
      long_description: params.long_description,
      cover_url_id:
        Number(params.cover_url_id) === 0 ? null : params.cover_url_id,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  const getOtherData = async () => {
    const mediaList = await fetchDataAndReturn(
      `mediaUploads?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        filterType: 'type',
        filterValue: 'key_insights_sections',
        orderBy: 'name',
        order: 'asc',
        searchTerm: '',
      })}`,
      'mediaUploads',
    );
    setMediaUploads(mediaList || []);
  };

  return (
    <PageDetailView
      header={`Key Insights Section ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Section Name'
            placeholder='Section Name'
            value={params.name}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />

          <TextInput
            title='Display Title'
            placeholder='Display Title'
            value={params.display_title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_title: e.target.value,
              }))
            }
          />

          <TextInput
            title='Short Description'
            subtitle='(Character limit 100)'
            placeholder='Short Description'
            value={params.short_description}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                short_description: e.target.value,
              }))
            }
            maxLength={100}
          />

          <TextInput
            title='Long Description'
            placeholder='Long Description'
            value={params.long_description}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                long_description: e.target.value,
              }))
            }
          />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='p-0 text-sm'>Cover Image</label>
              <label className='mb-1 p-0 text-xs opacity-60'>
                Illustration that will appear on the top
              </label>
              <select
                value={params.cover_url_id}
                name={'CoverImage'}
                className='flex-auto relative w-full m-0 mb-1 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                onChange={e => {
                  setParams(prevState => ({
                    ...prevState,
                    cover_url_id: e.target.value,
                  }));
                }}
              >
                <option selected value={0}>
                  -- Choose an image --
                </option>

                {mediaUploads.map(media => {
                  return (
                    <option key={media.id} value={media.id}>
                      {media.name}
                    </option>
                  );
                })}
              </select>
              {(() => {
                if (Number(params?.cover_url_id) > 0) {
                  const media = mediaUploads.find(media => {
                    return Number(media.id) === Number(params.cover_url_id);
                  });

                  if (media) {
                    return (
                      <div className='relative inline-block w-28 mb-2'>
                        <img
                          src={media.url}
                          alt='Image Url'
                          className='w-full h-auto'
                        />
                      </div>
                    );
                  }
                }
              })()}
            </div>
          </div>
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
