import PageListView from '../../components/Page/PageListView';

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Gut Kits', accessor: 'num_kits_gut' },
  { Header: 'Vaginal Kits', accessor: 'num_kits_vaginal' },
  { Header: 'Stool Chemistry Kits', accessor: 'num_kits_stool_chemistry' },
  { Header: 'Consult Credits', accessor: 'consult_credits' },
];

export default function ShopifyProducts() {
  return (
    <PageListView
      header='Shopify Products'
      rightButton={{
        title: 'Create New',
        url: '/shopifyProducts/new/0',
      }}
      tableProps={{
        type: 'shopifyProducts',
        route: 'shopifyProducts',
        columns: columns,
        searchable: true,
        onRowClick: (row: { original: { id: number; variant_id: number } }) =>
          window.open(
            `/shopifyProducts/${row.original.id}/${row.original.variant_id}`,
            '_blank',
          ),
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Variant ID',
            filter: 'variant_id',
          },
          {
            label: 'Name',
            filter: 'name',
          },
          {
            label: 'Lookup Code',
            filter: 'lookup_code',
          },
          {
            label: 'Type',
            filter: 'type',
          },
        ],
      }}
    />
  );
}
