import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

export default function Guide() {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);

  const updateGuide = async () => {
    setErrors([]);
    setUpdated(false);

    const updateData = {
      age_group: params.age_group, 
      pregnancy: params.pregnancy, 
      ttc: params.ttc,
    }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/guides/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };

  const getGuide = async () => {
    setFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/guides/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { guide } = await res.json();

      setParams(guide);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };
  useEffect(() => {
    setRoute('guides');
    if (id !== 'new') {
      getGuide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Guide {id}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateGuide()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Guide {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this guide:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Guide information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Webflow ID</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.webflow_id}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Display Title
                          </label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.display_title}
                          </div>
                        </div>
                      </div>

                      <div className='mt-4 flex-1 max-w-full'>
                        <div className='flex'>
                          <input
                            className='cursor-pointer w-4'
                            type='checkbox'
                            checked={params.published}
                            disabled={true}
                          />
                          <label className='ml-2 pt-1 text-sm'>Published</label>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Age Group</label>
                          <select
                            value={params.age_group}
                            name='Age Group'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                age_group: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option disabled selected>
                              -- Choose a status --
                            </option>
                            <option value={'baby'}>Baby</option>
                            <option value={'toddler'}>Toddler</option>
                            <option value={'child'}>Child</option>
                            <option value={'adult'}>Adult</option>
                          </select>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Pregnancy Related
                          </label>
                          <select
                            value={params.pregnancy}
                            name='Pregnancy related?'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                pregnancy: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option disabled selected>
                              -- Choose a status --
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            TTC Related
                          </label>
                          <select
                            value={params.ttc}
                            name='TTC related?'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                ttc: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option disabled selected>
                              -- Choose a status --
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Description
                          </label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            {params.description}
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>URL</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            <Link
                              to={{
                                pathname: params.url,
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='underline text-blue-600'
                            >
                              {params.url}
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Image URL</label>
                          <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                            <Link
                              to={{
                                pathname: params.image_url,
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='underline text-blue-600'
                            >
                              {params.image_url}
                            </Link>
                          </div>
                          {params.image_url && (
                            <div className='relative inline-block w-28 mt-4'>
                              <img
                                src={params.image_url}
                                alt='Imagel Url'
                                className='w-full h-auto'
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
