import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';

export default function SetPassword () {
  const location = useLocation();
  const [password, setPassword] = useState();
  const [verificationToken, setVerificationToken] = useState();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    async function authorizeToken () {
      let searchParams = await new URLSearchParams(location.search);
      let token = await searchParams.get('token');
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/authorize/${token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        setLoading(false);
        setVerificationToken(token);
      } else if (res.status === 500 || res.status === 404) {
        setFetchError(
          `There was a problem processing your request, please try again later or contact support.`,
        );
        setLoading(false);
      } else {
        const { error } = await res.json();
        setFetchError(
          error ? error : `Something went wrong, please try again.`,
        );
        setLoading(false);
      }
    }
    authorizeToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setNewPassword = async e => {
    e.preventDefault();
    if (password) {
      const params = { password };
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/set-password/${verificationToken}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        },
      );
      if (res.status === 200) {
        setSuccess(true);
      } else if (res.status === 500 || res.status === 404) {
        setError(
          `There was a problem processing your request, please try again later or contact support.`,
        );
      } else {
        const { error } = await res.json();
        setError(error ? error : `Something went wrong, please try again.`);
      }
    } else {
      setError(`Password is required.`);
    }
  };
  if (loading) {
    return (
      <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
        <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
          <div className='flex flex-col max-w-lg justify-center'>
            <h1 className='mb-4 mx-0 text-4xl font-serif'>
              Finding your account...
            </h1>
            <span className='text-sm mb-4'>
              Taking longer than expected?{' '}
              <NavLink
                className='text-green-300 ml-1 underline hover:text-green-400'
                to='/login'
              >
                Go back
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (success) {
    return (
      <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
        <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
          <div className='flex flex-col max-w-lg justify-center'>
            <h1 className='mb-4 mx-0 text-4xl font-serif'>Password updated!</h1>
            <span className='text-sm mb-4'>
              {`You've successfully updated your password. `}
              <NavLink
                className='text-green-300 ml-1 underline hover:text-green-400'
                to='/login'
              >
                Login to continue!
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (fetchError) {
    return (
      <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
        <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
          <div className='flex flex-col max-w-lg justify-center'>
            <h1 className='mb-4 mx-0 text-4xl font-serif'>{fetchError}</h1>
            <span className='text-sm mb-4'>
              Please try again or contact support if you think this is an issue.
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='grid grid-cols-1 min-h-screen bg-grey-100 items-center'>
      <div className='justify-center self-center max-h-screen px-8 py-5 z-50 overflow-y-auto'>
        <div className='flex flex-col max-w-lg justify-center'>
          <h1 className='mb-4 mx-0 text-4xl font-serif'>Set your password</h1>
          <form autoComplete='on'>
            <div className='grid grid-cols-1 gap-6 grid-flow-row'>
              <div className='flex relative'>
                <label htmlFor='Password' />
                <input
                  aria-label='Password'
                  type='password'
                  placeholder='Password (minimum 8 characters)'
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setError(null);
                  }}
                  className='text-sm w-full h-12 py-3 px-4 bg-white rounded-md leading-6 outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                />
              </div>
            </div>
            <div className='my-4 text-red-300 text-xs'>{error}</div>
            <button
              onClick={e => setNewPassword(e)}
              className='text-sm bg-green-300 text-white m-0 py-3 px-4 rounded-md leading-5 inline-block items-start text-center transition-all duration-200 ease-in-out delay-75 hover:bg-green-400'
            >
              Continue
            </button>
            <div className='mt-6 w-full flex flex-row text-sm'>
              Login instead?{' '}
              <NavLink
                to='/login'
                className='text-green-300 ml-1 underline hover:text-green-400'
              >
                Go back
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
