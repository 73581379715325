import { getAgentColor, getStatusColor } from '../../pages/Consults';
import { checkValidDates } from '../../helpers';
import { formatDate } from '../../lib/utils/formatDate';
import { formatDateTime } from '../../lib/utils/formatDate';
import X from '../../assets/iconography/x.svg';
import moment from 'moment';
import c from 'classnames';

const getEmailStatusColor = emailStatus => {
  let color = '';
  switch (emailStatus) {
    case 'delivered':
      color = 'bg-green-100';
      break;
    case 'bounce':
    case 'spamreport':
      color = 'bg-red-100';
      break;
    default:
      color = 'bg-yellow-100';
  }
  return color;
};

export const messageTableColumns = [
  {
    Header: 'Sent at',
    accessor: row => formatDateTime(row.received_at),
    Cell: ({ value }) => (
      <div>
        <span className={c('text-xs')}>{value}</span>
      </div>
    ),
  },
  {
    Header: 'From',
    accessor: row => `${row.source_type}/${row.display_name}`,
    Cell: ({ value }) => (
      <div>
        <span className={c('text-xs')}>{value}</span>
      </div>
    ),
  },
  {
    Header: 'Message',
    accessor: row => row.message,
    Cell: ({ value }) => (
      <div className='break-all'>
        <span className={c('text-xs')}>{value}</span>
      </div>
    ),
  },
];

export const npsTableColumns = [
  {
    Header: 'Kit ID',
    accessor: row => row.kit_id,
  },
  {
    Header: 'Response Date',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Rating',
    accessor: row => row.rating,
  },
  {
    Header: 'Feedback',
    accessor: row => row.feedback,
  },
];

export const programsTableColumns = [
  {
    Header: 'Program name',
    accessor: 'shopify_product_name',
  },
  {
    Header: 'Start Date',
    id: 'start_date',
    accessor: row =>
      row.start_date ? moment(row.start_date).format('YYYY-MM-DD') : '',
  },
];

export const subscriptionsTableColumns = [
  {
    Header: 'Name',
    accessor: 'shopify_product_name',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Created At',
    id: 'date_created_at',
    accessor: row =>
      row.date_created_at
        ? moment(row.date_created_at).format('YYYY-MM-DD')
        : '',
  },
  {
    Header: 'Ends',
    id: 'date_subscription_ends',
    accessor: row =>
      row.date_subscription_ends
        ? moment(row.date_subscription_ends).format('YYYY-MM-DD')
        : '',
  },
];

export const consultTableColumns = [
  {
    Header: 'Scheduled',
    id: 'scheduled_dt',
    accessor: row =>
      row.scheduled_dt
        ? moment
            .unix(row.scheduled_dt)
            .tz('US/Central')
            .format('YYYY-MM-DDTHH:mm')
        : '',
  },
  {
    Header: 'Amount paid',
    accessor: 'payment',
  },
  {
    Header: 'Agent',
    accessor: row =>
      row.consult_agent?.email
        ? row.consult_agent?.email
        : row.agent
        ? row.agent
        : 'No Agent',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getAgentColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Kit ID',
    accessor: row => (row.kit_id ? row.kit_id : 'NA'),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getStatusColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
];

export const consultCreditTableColumns = [
  {
    Header: 'Date Added',
    accessor: row => new moment(row.created_at).format('YYYY-MM-DD'),
  },
  {
    Header: 'Type',
    accessor: row => row.type,
  },
  {
    Header: 'Notes',
    accessor: row => row.notes,
  },
  {
    Header: 'Status',
    accessor: row => convertStatus(row.status),
  },
];

const convertStatus = status => {
  switch (status) {
    case 'SCHEDULED':
      return 'Used';
    case 'CREATED':
      return 'Available';
    case 'DELETED':
      return 'Deleted';
    default:
      return;
  }
};

export const feedbackTableColumns = [
  {
    Header: 'Kit Id',
    accessor: row => row.kit_id,
  },
  {
    Header: 'Response Date',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Answer',
    accessor: row => (row.answer === 1 ? 'Thumbs Up' : 'Thumbs Down'),
  },
  {
    Header: 'Page ID',
    accessor: row => row.page_id,
  },
  {
    Header: 'Page Type',
    accessor: row => row.page_type,
  },
  {
    Header: 'Item',
    accessor: row => row.item,
  },
  {
    Header: 'Feedback',
    accessor: row => row.feedback,
  },
];

export const emailTableColumns = [
  {
    Header: 'Sent At',
    accessor: row =>
      formatDate(row.published_at ? row.published_at : row.created_at),
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Subject',
    accessor: row => (row.template ? row.template.name : row.template_id),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <span
          className={c('text-sm rounded-2xl p-2', getEmailStatusColor(value))}
        >
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Opens',
    accessor: 'opens',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
];
