import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';


import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function Partner() {
  const route = 'partners';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'partner');
  const [disabledFields, setDisabledFields] = useState<{
    slug: boolean;
    email: boolean;
    api_key: boolean;
  } | null>(null);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      api_key: '',
      email: '',
      light_logo: '',
      logo: '',
      mainaccount_id: '',
      name: '',
      pdf_logo: '',
      report_config: '',
      slug: '',
      status: '',
      typeform_survey_id: '',
    },
  });

  const dirtyFields = formState.dirtyFields;

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    } else {
      setParams({
        report_config: {},
        hide_consults: 1,
      });
    }
  }, [id]);

  useEffect(() => {
    if (params.id && !disabledFields) {
      setDisabledFields({
        slug: params.slug?.length > 0,
        email: params.email?.length > 0,
        api_key: params.api_key?.length > 0,
      });
    }
  }, [params, disabledFields]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      name: params.name,
      slug: params.slug,
      email: params.email,
      status: params.status,
      api_key: params.api_key,
      logo: params.logo,
      light_logo: params.light_logo,
      pdf_logo: params.pdf_logo,
      report_config:
        params.report_config && params.report_config.length > 0
          ? typeof params.report_config === 'string'
            ? JSON.parse(params.report_config)
            : params.report_config
          : {},
      hide_consults: params.hide_consults,
      mainaccount_id:
        params.mainaccount_id?.length > 0 ? params.mainaccount_id : null,
      typeform_survey_id:
        params.typeform_survey_id?.length > 0
          ? params.typeform_survey_id
          : null,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
        setDisabledFields(null);
        reset();
      }
    }
  };

  return (
    <PageDetailView
      header={`Partner ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={handleSubmit(onSave)}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Information'}>
          <TextInput
            title='Name'
            placeholder='Name'
            value={params.name}
            isDirty={dirtyFields.name}
            register={{
              ...register('name', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    name: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Slug'
            subtitle='URL prefix for [slug].tinyhealth.com'
            placeholder='slug'
            value={params.slug}
            disabled={disabledFields?.slug}
            isDirty={dirtyFields.slug}
            register={{
              ...register('slug', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    slug: e.target.value
                      .trim()
                      .toLowerCase()
                      .replaceAll(' ', '')
                      .replaceAll('.', ''),
                  })),
              }),
            }}
          />

          <TextInput
            title='Email'
            placeholder='Email'
            value={params.email}
            disabled={disabledFields?.email}
            register={{
              ...register('email', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    email: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.email}
          />

          <SelectInput
            title='Status'
            options={[
              { value: '', title: '-- Choose a type --', disabled: true },
              { value: 'active', title: 'Active' },
              { value: 'inactive', title: 'Inactive' },
            ]}
            value={params.status ?? ''}
            register={{
              ...register('status', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    status: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.status}
          />

          <TextInput
            title='API Key'
            placeholder='API Key'
            value={params.api_key}
            disabled={disabledFields?.api_key}
            register={{
              ...register('api_key', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    api_key: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.api_key}
          />

          <TextInput
            title='Logo'
            placeholder='Logo'
            value={params.logo}
            register={{
              ...register('logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.logo}
          />

          <TextInput
            title='Light Logo'
            placeholder='Light Logo'
            value={params.light_logo}
            register={{
              ...register('light_logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    light_logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.light_logo}
          />

          <TextInput
            title='PDF Logo'
            placeholder='PDF Logo'
            value={params.pdf_logo}
            register={{
              ...register('pdf_logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    pdf_logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.pdf_logo}
          />

          <TextInput
            type='textarea'
            title='Report Config'
            placeholder='Report Config'
            value={
              typeof params.report_config === 'object'
                ? JSON.stringify(params.report_config)
                : params.report_config
            }
            isDirty={dirtyFields.report_config}
            register={{
              ...register('report_config', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    report_config: e.target.value,
                  })),
              }),
            }}
          />

          <CheckboxInput
            title='Hide Consults'
            checked={params.hide_consults}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                hide_consults: Number(e.target.checked),
              }))
            }
          />

          <TextInput
            title='Main Account ID'
            placeholder='Main Account ID'
            value={params.mainaccount_id}
            isDirty={dirtyFields.mainaccount_id}
            register={{
              ...register('mainaccount_id', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    mainaccount_id: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Typeform Survey ID'
            placeholder='Typeform Survey ID'
            value={params.typeform_survey_id}
            isDirty={dirtyFields.typeform_survey_id}
            register={{
              ...register('typeform_survey_id', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    typeform_survey_id: e.target.value,
                  })),
              }),
            }}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
