import { useMemo } from 'react';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';

export default function PractitionerDropdown({
  defaultValue,
  onChange,
  practitioners,
}) {
  const selectOptions = useMemo(() => {
    if (practitioners?.length) {
      const practitionerOptions = practitioners.map(practitioner => {
        return {
          label: `${practitioner?.user?.first_name} ${practitioner?.user?.last_name}`,
          value: practitioner.id,
          email: practitioner.user.email,
        };
      });
      return practitionerOptions;
    }
    return [];
  }, [practitioners]);

  return (
    <SearchableDropdown
      defaultValue={defaultValue}
      onChange={onChange}
      selectOptions={selectOptions}
      searchKeys={['email', 'label']}
    />
  );
}
