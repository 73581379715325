import c from 'classnames';

import ChevronLeft from '../../assets/iconography/chevron_left.svg';
import ChevronRight from '../../assets/iconography/chevron_right.svg';

const PageChangeButton = ({ type, page, maxPage, setPage }) => {
  const disabled = type === 'decrement' ? page === 1 : page === maxPage + 1;
  const classnames = 'relative px-4 py-3 bg-tansparent border-solid';
  const disabledClassnames = 'cursor-default border-t border-b border-gray-300';
  const enabledClassnames =
    'cursor-pointer border hover:bg-gray-100 border-gray-500';
  const incrementClassnames = 'border-r rounded-r-md';
  const decrementClassnames = 'border-l rounded-l-md';
  const handleClick = () => {
    if (type === 'increment') {
      setPage(page + 1);
    } else if (type === 'decrement') {
      setPage(page - 1);
    }
  };
  return (
    <button
      disabled={disabled}
      className={c(
        classnames,
        disabled ? disabledClassnames : enabledClassnames,
        type === 'increment' ? incrementClassnames : decrementClassnames,
      )}
      onClick={handleClick}
    >
      <img
        src={type === 'decrement' ? ChevronLeft : ChevronRight}
        alt={`page ${type === 'decrement' ? 'down' : 'up'}`}
        className={c('filter h-3', disabled ? 'invert-80' : 'invert-20')}
      />
    </button>
  );
};

const TablePaginator = ({ page, maxPage, setPage }) => {
  return (
    <>
      {maxPage >= 1 && (
        <div className='m-auto pt-6 px-6 py-6 border-t border-solid border-gray-300'>
          <div className='flex flex-wrap'>
            <div className='max-w-full flex-none'>
              <div className='flex flex-nowrap items-center m-0'>
                <div className='relative z-10 m-0'>
                  <PageChangeButton
                    type='decrement'
                    page={page}
                    maxPage={maxPage}
                    setPage={setPage}
                  />
                  <PageChangeButton
                    type='increment'
                    page={page}
                    maxPage={maxPage}
                    setPage={setPage}
                  />
                  <span className='ml-8 text-black font-medium m-0 text-sm leading-8'>
                    Page {page} of {maxPage + 1}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablePaginator;
