import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './styles.css';

import SamplingTypeBadge from '../../components/SamplingTypeBadge/SamplingTypeBadge';

export default function FulfillmentKit({ fulfillment_kit, onClick }) {
  const [params, setParams] = useState({});

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      comments: params.comments,
      first_name: params.first_name,
      last_name: params.last_name,
      membership_plan: params.membership_plan,
      membership_type: params.membership_type,
    },
  });

  const dirtyFields = formState.dirtyFields;

  const checkAllFieldsFinished = () => {
    return (
      params.kit_id &&
      params.tracking_to_lab_microbiome &&
      (fulfillment_kit.sampling_type === 'stool_chemistry'
        ? params.tracking_to_lab_stoolchemistry
        : true)
    );
  };

  return (
    <div>
      <li key={fulfillment_kit.id}>
        <ul className='ml-4'>
          {fulfillment_kit.item_name && (
            <li>
              <span className='text-sm'>
                Item name: {fulfillment_kit.item_name}
              </span>
            </li>
          )}
          {(() => {
            const sampling_types = [];
            if (!(fulfillment_kit.status || '').startsWith('void')) {
              sampling_types.push(fulfillment_kit.sampling_type);
            }
            if (fulfillment_kit.sampling_type_variant === 'stool_chemistry') {
              sampling_types.push(fulfillment_kit.sampling_type_variant);
            }

            return (
              <li>
                <span className='text-sm'>
                  Sampling type:{' '}
                  <SamplingTypeBadge sampling_type={sampling_types} />
                </span>
              </li>
            );
          })()}
          <span className='text-sm w-full'>
            <input
              {...register('kit_id', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    kit_id: e.target.value,
                  })),
              })}
              placeholder='kit_id'
              value={fulfillment_kit.kit_id}
              className={`flex-auto relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-xs ${
                dirtyFields.kit_id ? 'dirty' : ''
              }`}
              disabled={fulfillment_kit.kit_id ? true : false}
            />
            <input
              {...register('tracking_to_lab_microbiome', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    tracking_to_lab_microbiome: e.target.value,
                  })),
              })}
              placeholder='Tracking to lab (microbiome)'
              value={fulfillment_kit.tracking_to_lab_microbiome}
              className={`flex-auto w-64 relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-xs ${
                dirtyFields.tracking_to_lab_microbiome ? 'dirty' : ''
              }`}
              disabled={
                fulfillment_kit.tracking_to_lab_microbiome ? true : false
              }
            />
            {fulfillment_kit.sampling_type_variant !== 'stool_chemistry' ? (
              ''
            ) : (
              <input
                {...register('tracking_to_lab_stoolchemistry', {
                  onChange: e =>
                    setParams(prevState => ({
                      ...prevState,
                      tracking_to_lab_stoolchemistry: e.target.value,
                    })),
                })}
                placeholder='Tracking to lab (stoolchemistry)'
                value={fulfillment_kit.tracking_to_lab_stoolchemistry}
                className={`flex-auto w-64 relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-xs ${
                  dirtyFields.tracking_to_lab_stoolchemistry ? 'dirty' : ''
                }`}
                disabled={
                  fulfillment_kit.tracking_to_lab_stoolchemistry ? true : false
                }
              />
            )}
            <button
              onClick={handleSubmit(() => {
                onClick(
                  fulfillment_kit.fulfillment_id,
                  fulfillment_kit.id,
                  params.kit_id,
                  params.tracking_to_lab_microbiome,
                  params.tracking_to_lab_stoolchemistry,
                );
                setParams({
                  kit_id: null,
                  tracking_to_lab_microbiome: null,
                  tracking_to_lab_stoolchemistry: null,
                });
                reset();
              })}
              className='h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
              type='submit'
              disabled={!checkAllFieldsFinished()}
            >
              Save
            </button>
          </span>
        </ul>
      </li>
    </div>
  );
}
