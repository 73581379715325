import produce from 'immer';

const route = set => ({
  route: {
    route: undefined, // undefined is default, null is for when route is known not to exist,
    setRoute: (route, token) => {
      set(
        produce(state => {
          state.route.route = route;
          state.route.token = token;
        }),
      );
    },
  },
});

export default route;
