import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Display Title',
    accessor: 'display_title',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Sampling Group',
    accessor: 'sampling_group',
  },
  { Header: 'Published', accessor: 'published' },
];

export default function ActionPlanRecommendations () {
  return (
    <PageListView
      header='Action Plan Recommendations'
      rightButton={{
        title: 'Create New',
        url: '/actionPlanRecommendations/new',
      }}
      tableProps={{
        type: 'actionPlanRecommendations',
        route: 'actionPlanRecommendations',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Display Title',
            filter: 'display_title',
          },
          {
            label: 'Category',
            filter: 'category',
          },
          {
            label: 'Sampling Group',
            filter: 'sampling_group',
          },
        ],
      }}
    />
  );
}
