const PageContainer = ({ children }) => {
  return (
    <div className='flex flex-auto bg-gray-50 items-stretch max-w-full'>
      <div className='relative flex-auto max-w-full'>
        <div className='block pt-2 px-4 md:px-8 md:pt-6 mx-auto my-3'>
          {children}
        </div>
      </div>
    </div>
  );
};
export default PageContainer;
