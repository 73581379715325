import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { API_BASE_PATH } from './config/api';
import useStore from './stores';
import { jwtToken } from './lib/constants';

import E404 from './pages/E404';
import Loading from './pages/Loading';
import Logout from './pages/Logout';
import Verify from './pages/Verify';

import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import SetPassword from './pages/SetPassword';
import Signup from './pages/Signup';

import UnderConstruction from './pages/UnderConstruction';
import MainAccounts from './pages/MainAccounts';
import MainAccount from './pages/MainAccount';
import TinyAccounts from './pages/TinyAccounts';
import TinyAccount from './pages/TinyAccount';
import InitializeKit from './pages/InitializeKit';
import Kits from './pages/Kits';
import Kit from './pages/Kit';
import KitNote from './pages/KitNote';
import Fulfillments from './pages/Fulfillments';
import FulfillmentsV2 from './pages/FulfillmentsV2';
import Fulfillment from './pages/Fulfillment';
import UnusedKits from './pages/UnusedKits';
import Consults from './pages/Consults';
import Consult from './pages/Consult';
import ConsultNote from './pages/ConsultNote';
import Subscriptions from './pages/Subscriptions';
import Subscription from './pages/Subscription';
import Practitioners from './pages/Practitioners';
import Practitioner from './pages/Practitioner';
import Partners from './pages/Partners';
import Partner from './pages/Partner';
import PartnerRegister from './pages/PartnerRegister';
import PractitionerSwap from './pages/PractitionerSwap';
import KeyInsights from './pages/KeyInsights';
import KeyInsight from './pages/KeyInsight';
import KeyInsightsSections from './pages/KeyInsightsSections';
import KeyInsightsSection from './pages/KeyInsightsSection';
import KeyInsightsCategories from './pages/KeyInsightsCategories';
import KeyInsightsCategory from './pages/KeyInsightsCategory';
import KitPrep from './pages/KitPrep';
import ReportTaxaCategories from './pages/ReportTaxaCategories';
import ReportTaxaCategory from './pages/ReportTaxaCategory';
import Guides from './pages/Guides';
import Guide from './pages/Guide';
import Conditions from './pages/Conditions';
import Condition from './pages/Condition';
import ActionPlanRecommendations from './pages/ActionPlanRecommendations';
import ActionPlanRecommendation from './pages/ActionPlanRecommendation';
import Recommendations from './pages/Recommendations';
import Recommendation from './pages/Recommendation';
import RecommendationItems from './pages/RecommendationItems';
import RecommendationItem from './pages/RecommendationItem';
import AdminLogs from './pages/AdminLogs';
import AdminLog from './pages/AdminLog';
import MediaUploads from './pages/MediaUploads';
import MediaUpload from './pages/MediaUpload';
import NoteSummary from './pages/NoteSummary';
import NoteSummaryDetail from './pages/NoteSummaryDetail';
import ProfileQuestions from './pages/ProfileQuestions';
import ProfileQuestion from './pages/ProfileQuestion';
import ShopifyProducts from './pages/ShopifyProducts';
import ShopifyProduct from './pages/ShopifyProduct';
import Macros from './pages/Macros';
import Macro from './pages/Macro';

import Navbar from './components/Navbar';

import './_base.css';

const App = () => {
  const LOCAL_TOKEN = localStorage.getItem(jwtToken);
  const admin = useStore(state => state.admin.admin);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function authorize() {
      if (LOCAL_TOKEN) {
        const res = await fetch(`${API_BASE_PATH}/ops/admins/authorize`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${LOCAL_TOKEN}`,
            'x-access-token': LOCAL_TOKEN,
            'Content-Type': 'application/json',
          },
        });
        if (res.status === 200) {
          // token and admin match, and the admin exists. Update the admin store to the most recent version of admin
          const { admin } = await res.json();
          await setAdmin(admin, LOCAL_TOKEN);
        } else {
          // token and admin don't match, or the admin doesn't exist, proceed to log them out
          await setAdmin(undefined, undefined);
        }
      } else {
        await setAdmin(undefined, undefined);
      }
      await setLoading(false);
    }
    authorize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return <Loading />;
  }
  if (admin === undefined || !token || !admin?.verified) {
    return (
      <BrowserRouter>
        <div className='min-h-screen relative bg-transparent'>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/set-password' component={SetPassword} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/404' component={E404} />
            <Route>
              <Redirect to='/' />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <div className='min-h-screen relative bg-transparent flex flex-col lg:flex-row'>
        <Navbar />
        <div className='flex flex-1 items-stretch max-w-full mt-16 lg:mt-0 lg:pl-60'>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/kits' />
            </Route>
            <Route exact path='/dashboard' component={UnderConstruction} />
            <Route exact path='/mainaccounts' component={MainAccounts} />
            <Route exact path='/mainaccounts/:id' component={MainAccount} />
            <Route exact path='/tinyaccounts' component={TinyAccounts} />
            <Route exact path='/tinyaccounts/:id' component={TinyAccount} />
            <Route exact path='/kits' component={Kits} />
            <Route exact path='/kit_prep' component={KitPrep} />
            <Route exact path='/kits/initialize' component={InitializeKit} />
            <Route exact path='/kits/:id' component={Kit} />
            <Route exact path='/kits/:id/note' component={KitNote} />
            <Route exact path='/unused_kits' component={UnusedKits} />
            <Route exact path='/fulfillments' component={Fulfillments} />
            <Route exact path='/fulfillments/:id' component={Fulfillment} />
            <Route exact path='/fulfillments_v2' component={FulfillmentsV2} />
            <Route exact path='/consults' component={Consults} />
            <Route exact path='/consults/:id' component={Consult} />
            <Route exact path='/consults/:id/note' component={ConsultNote} />
            <Route exact path='/subscriptions' component={Subscriptions} />
            <Route exact path='/subscriptions/:id' component={Subscription} />
            <Route exact path='/practitioners' component={Practitioners} />
            <Route exact path='/practitioners/:id' component={Practitioner} />
            <Route exact path='/partners' component={Partners} />
            <Route exact path='/partners/:id' component={Partner} />
            <Route exact path='/partner_register' component={PartnerRegister} />
            <Route
              exact
              path='/practitioner_swap'
              component={PractitionerSwap}
            />
            <Route exact path='/keyInsights' component={KeyInsights} />
            <Route exact path='/keyInsights/:id' component={KeyInsight} />
            <Route
              exact
              path='/keyInsightsSections'
              component={KeyInsightsSections}
            />
            <Route
              exact
              path='/keyInsightsSections/:id'
              component={KeyInsightsSection}
            />
            <Route
              exact
              path='/keyInsightsCategory'
              component={KeyInsightsCategories}
            />
            <Route
              exact
              path='/keyInsightsCategory/:id'
              component={KeyInsightsCategory}
            />
            <Route
              exact
              path='/reportTaxaCategories'
              component={ReportTaxaCategories}
            />
            <Route
              exact
              path='/reportTaxaCategories/:id'
              component={ReportTaxaCategory}
            />
            <Route exact path='/guides' component={Guides} />
            <Route exact path='/guides/:id' component={Guide} />
            <Route
              exact
              path='/actionPlanRecommendations'
              component={ActionPlanRecommendations}
            />
            <Route
              exact
              path='/actionPlanRecommendations/:id'
              component={ActionPlanRecommendation}
            />
            <Route exact path='/conditions' component={Conditions} />
            <Route exact path='/conditions/:id' component={Condition} />
            <Route exact path='/recommendations' component={Recommendations} />
            <Route
              exact
              path='/recommendations/:id'
              component={Recommendation}
            />
            <Route
              exact
              path='/recommendationItems'
              component={RecommendationItems}
            />
            <Route
              exact
              path='/recommendationItems/:id'
              component={RecommendationItem}
            />
            <Route exact path='/mediaUploads' component={MediaUploads} />
            <Route exact path='/mediaUploads/:id' component={MediaUpload} />
            <Route exact path='/noteSummary' component={NoteSummary} />
            <Route
              exact
              path='/noteSummary/:id'
              component={NoteSummaryDetail}
            />
            <Route exact path='/macros' component={Macros} />
            <Route exact path='/macros/:id' component={Macro} />
            <Route exact path='/shopifyProducts' component={ShopifyProducts} />
            <Route
              exact
              path='/shopifyProducts/:id/:variant_id'
              component={ShopifyProduct}
            />
            <Route
              exact
              path='/profileQuestions'
              component={ProfileQuestions}
            />
            <Route
              exact
              path='/profileQuestions/:id'
              component={ProfileQuestion}
            />
            <Route exact path='/logs' component={AdminLogs} />
            <Route exact path='/logs/:id' component={AdminLog} />
            <Route exact path='/settings' component={UnderConstruction} />
            {/* <Route exact path='/overview' component={UnderConstruction} /> */}
            <Route exact path='/profile' component={UnderConstruction} />
            <Route
              exact
              path='/verify/:verification_token'
              component={Verify}
            />
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/404' component={E404} />
            <Route>
              <Redirect to='/404' />
            </Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
