import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import { commands } from '@uiw/react-md-editor';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import BackArrow from '../../assets/iconography/back_arrow.svg';
import X from '../../assets/iconography/x.svg';

export default function KitNote() {
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(true);
  const [params, setParams] = useState({});
  const [errors, setErrors] = useState([]);

  const regenerateNote = async () => {
    setFetching(true);
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/kits/${id}/regenerateNote`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      const { note } = await res.json();
      setParams(note);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };

  useEffect(() => {
    async function getKitNote() {
      setFetching(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}/note`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const { note } = await res.json();
        setParams(note);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
    setRoute('kits');
    getKitNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='flex-none max-w-full mr-2 lg:mr-6'>
              <NavLink
                to={`/kits/${id}`}
                className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
              >
                <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                  <div className='m-0'>
                    <img
                      src={BackArrow}
                      className='relative block h-5 w-5'
                      alt='Tiny Ops'
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Expert review kit {id}
              </h1>
            </div>
          </div>
          <div className='mb-3'>
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this expert review:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='flex flex-col justify-center items-start lg:flex-1'>
              <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                  <div className='p-8 rounded-md' data-color-mode='light'>
                    <div className='pb-4 flex gap-4 items-center justify-between'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Auto generated note
                      </h2>
                      <button
                        onClick={regenerateNote}
                        disabled={fetching}
                        className={`flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25`}
                      >
                        Regenerate
                      </button>
                    </div>
                    <MarkdownEditor
                      height={300}
                      value={params?.original_value}
                      preview={'edit'}
                      hideToolbar={true}
                      textareaProps={{ disabled: true }}
                      commands={[commands.codeEdit]}
                      highlightEnable={false}
                    />
                    <div className='pt-6 pb-4 flex gap-4 items-center justify-between'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        V3 Note
                      </h2>
                    </div>
                    <MarkdownEditor
                      height={300}
                      value={params?.note_v3}
                      preview={'edit'}
                      hideToolbar={true}
                      textareaProps={{ disabled: true }}
                      commands={[commands.codeEdit]}
                      highlightEnable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
