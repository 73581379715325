import { formatDate } from '../../lib/utils/formatDate';

export const feedbackTableColumns = [
  {
    Header: 'Response Date',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Answer',
    accessor: row => (row.answer === 1 ? 'Thumbs Up' : 'Thumbs Down'),
  },
  {
    Header: 'Page ID',
    accessor: row => row.page_id,
  },
  {
    Header: 'Page Type',
    accessor: row => row.page_type,
  },
  {
    Header: 'Item',
    accessor: row => row.item,
  },
  {
    Header: 'Feedback',
    accessor: row => row.feedback,
  },
];

export const npsTableColumns = [
  {
    Header: 'Response Date',
    accessor: row => formatDate(row.created_at),
  },
  {
    Header: 'Rating',
    accessor: row => row.rating,
  },
  {
    Header: 'Feedback',
    accessor: row => row.feedback,
  },
];
