import React, { useState } from 'react';

import useStore from '../../stores';
import { API_BASE_PATH } from '../../config/api';
import { formatDateTime } from '../../lib/utils/formatDate';
import { Link } from 'react-router-dom';
import SelectInput from '../../components/SelectInput/SelectInput';
import TextInput from '../../components/TextInput/TextInput';
import PageContainer from '../../components/Page/PageContainer';
import Table from '../../components/Table/index';
import PageHeader from '../../components/Page/PageHeader';

const columns = [
  {
    Header: 'Date Generated',
    accessor: row => formatDateTime(row.created_at),
    Cell: ({ value }) => <div className='text-sm'>{value}</div>,
  },
  {
    Header: 'Details',
    accessor: row => row,
    Cell: ({ value }) => (
      <div className='text-sm'>
        <ul>
          <li>Type: {value.type}</li>
          <li>
            To: {value.address_to?.name}, {value.address_to?.street1},{' '}
            {value.address_to?.street2}
          </li>
          <li>Number generated: {value.num_generated}</li>
        </ul>
      </div>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) =>
      value !== 'PURCHASED' ? (
        <div className='text-sm'>
          <ul>
            <li>{value}</li>
            <li>(Wait 10 seconds...)</li>
            <li>
              <Link
                to={{
                  pathname: '/kit_prep',
                }}
                onClick={() => {
                  window.location.reload();
                }}
                reloadDocument={true}
                className='text-green-300 underline hover:text-green-400'
              >
                Refresh
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        'DONE'
      ),
  },
  {
    Header: 'File Link',
    accessor: 'return_label_url',
    Cell: ({ value }) =>
      value ? (
        <a
          href={value}
          target='_blank'
          rel='noreferrer'
          className='text-sm text-green-300 underline hover:text-green-400'
        >
          Download
        </a>
      ) : (
        ''
      ),
  },
  {
    Header: 'Completed',
    accessor: row => row,
    Cell: ({ value }) =>
      value.is_complete ? (
        value.is_complete
      ) : value.status === 'PURCHASED' ? (
        <MarkAsComplete id={value.id} />
      ) : (
        ''
      ),
  },
];

const MarkAsComplete = ({ id }) => {
  const token = useStore(state => state.admin.token);

  const updatePendingBatchReturnLabel = async isComplete => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/return-labels/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isComplete }),
    });
    if (res.status === 200) {
      window.location.reload();
    }
    return { batchReturnLabels: null };
  };

  return (
    <button
      className='text-sm text-green-300 underline hover:text-green-400'
      onClick={() => updatePendingBatchReturnLabel(true)}
    >
      Mark as complete
    </button>
  );
};

const getPendingBulkReturnLabels = async token => {
  const res = await fetch(
    `${API_BASE_PATH}/ops/admins/return-labels?is_complete='pending'`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    },
  );
  if (res.status === 200) {
    const { batchReturnLabels } = await res.json();
    return { batchReturnLabels };
  }
  return { batchReturnLabels: null };
};

const GenerateBulkReturnLabelForm = () => {
  const [error, setError] = useState();
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [params, setParams] = useState({});

  const generateBulkReturnLabel = async (address_to, num_generated, type) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/return-labels`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address_to,
        num_generated: num_generated,
        type,
      }),
    });
    if (res.status === 200) {
      window.location.reload();

      for (let attempt = 1; attempt <= 6; attempt++) {
        //wait 10 seconds before checking if PDF is generated
        await new Promise(resolve => setTimeout(resolve, 10000));

        const { batchReturnLabels } = await getPendingBulkReturnLabels(token);
        if (batchReturnLabels) {
          let reload = true;
          for (const batchReturnLabel of batchReturnLabels) {
            if (batchReturnLabel.status !== 'PURCHASED') {
              reload = false;
            }
          }
          if (reload) {
            break;
          }
        }
      }
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem updating the fulfillment, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error);
    }
  };

  return (
    <>
      {error ? (
        <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
          <div className='flex flex-col p-6'>
            <div className='font-medium'>{error}</div>
          </div>
        </div>
      ) : null}
      <div className='flex-nowrap flex justify-between mt-4'>
        <div className='mt-0 flex-auto self-center'>
          <div>
            <h3 className='text-xl break-word font-serif font-semibold m-0'>
              Generate Return Labels
            </h3>
          </div>
        </div>
      </div>
      <div className='flex w-full'>
        <span className='flex w-full text-sm'>
          <SelectInput
            options={[
              { value: '', title: '-- Return Label Type --', disabled: true },
              { value: 'usps_first', title: 'USPS Ground Advantage' },
            ]}
            value={params.type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                type: e.target.value,
              }))
            }
            className={'flex-auto relative w-64'}
          />
          <TextInput
            type='number'
            placeholder='80'
            value={params.num_generated}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                num_generated: e.target.value,
              }))
            }
            className={'flex-auto relative w-64'}
          />
          <SelectInput
            options={[
              { value: '', title: '-- Address --', disabled: true },
              { value: 'PSOMAGEN_MD', title: 'Psomagen Rockville' },
            ]}
            value={params.address_to ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                address_to: e.target.value,
              }))
            }
            className={'flex-auto relative w-64'}
          />
          <button
            onClick={() =>
              generateBulkReturnLabel(
                params.address_to,
                params.num_generated,
                params.type,
              )
            }
            className='h-10 mt-4 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
            disabled={
              !(params.address_to && params.num_generated && params.type)
            }
          >
            Generate
          </button>
        </span>
      </div>
    </>
  );
};

export default function KitPrep() {
  return (
    <PageContainer>
      <div className='flex justify-between'>
        <PageHeader title={'Fulfillment: Kit Prep'} />
      </div>
      <GenerateBulkReturnLabelForm />
      <Table
        type={'returnLabels'}
        route={'return-labels'}
        columns={columns}
        defaultFilter={['pending']}
        filterType='is_complete'
        filters={['pending', 'completed']}
        searchable={false}
        onRowClick={() => {
          return;
        }}
      />
    </PageContainer>
  );
}
