interface PageDetailContainerProps {
  numColumns: number;
  children?: React.ReactNode;
}

const PageDetailContainer = ({
  numColumns,
  children,
}: PageDetailContainerProps) => {
  return (
    <form autoComplete='off' className='mb-10 lg:flex'>
      <div
        className={`flex flex-col justify-center items-start lg:flex-1 ${
          numColumns === 2 ? 'lg:flex-row' : ''
        }`}
      >
        {children}
      </div>
    </form>
  );
};

export default PageDetailContainer;
