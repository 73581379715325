import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import Logo from '../../assets/logo/logo.svg';

function Invalid (props) {
  return (
    <div className='fixed w-screen m-0 p-4 border-0 min-h-screen z-50'>
      <div className='flex flex-col p-2'>
        <div className='flex max-w-xs'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Health' />
          </div>
        </div>
      </div>
      <div className='my-4 mx-auto'>
        <div className='lg:ml-6 flex flex-col'>
          <h1 className='mb-4 text-xl'>
            Sorry, something went wrong validating this admin.
          </h1>
          <h2 className='mb-4 leading-6 text-gray-800'>{props.error}</h2>
        </div>
        <div className='lg:ml-6 flex flex-col'>
          <span className='m-0 text-sm text-purple-500'>
            Contact the person who requested admin access and try again.
          </span>
        </div>
      </div>
    </div>
  );
}

function Validating () {
  return (
    <div className='fixed w-screen m-0 p-4 border-0 min-h-screen z-50'>
      <div className='flex flex-col p-2'>
        <div className='flex max-w-xs'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Health' />
          </div>
        </div>
      </div>
      <div className='my-4 mx-auto'>
        <div className='lg:ml-6 flex flex-col'>
          <h1 className='mb-4 text-xl'>Validating...</h1>
          <h2 className='m-0 mb-4 leading-6 text-gray-800'>
            This might take some time.
          </h2>
          <HashLoader color='#009b87' size='35px' speedMultiplier='0.75' />
        </div>
      </div>
    </div>
  );
}

function Success (props) {
  return (
    <div className='fixed w-screen m-0 p-4 border-0 min-h-screen z-50'>
      <div className='flex flex-col p-2'>
        <div className='flex max-w-xs'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Health' />
          </div>
        </div>
      </div>
      <div className='my-4 mx-auto'>
        <div className='lg:ml-6 flex flex-col'>
          <h1 className='mb-4 text-xl'>{`They're all set!`}</h1>
          <h2 className='m-0 leading-6 text-gray-800'>
            {`${props.email} was validated successfully. We'll notify them via
            email!`}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default function Verify () {
  const { verification_token } = useParams();
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [invalid, setInvalid] = useState(false);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    async function verify () {
      if (!verification_token) {
        // display error
        setError(`Verification token not provided.`);
        setInvalid(true);
      } else {
        const res = await fetch(
          `${API_BASE_PATH}/ops/admins/verify/${verification_token}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'x-access-token': token,
            },
          },
        );
        if (res.status === 200) {
          const { email } = await res.json();
          setEmail(email);
          setVerified(true);
        } else if (res.status === 401) {
          await setAdmin(undefined, undefined);
        } else {
          const { error } = await res.json();
          setError(error);
          setInvalid(true);
        }
      }
    }
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (invalid) {
    return <Invalid error={error} />;
  } else if (verified) {
    return <Success email={email} />;
  }

  return <Validating />;
}
