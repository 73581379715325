import c from 'classnames';
import PageListView from '../../components/Page/PageListView';
import moment from 'moment';

const getCompletedColor = completed_dt => {
  return completed_dt === 'Missing' ? 'bg-red-100' : '';
};

export const getAgentColor = agent => {
  return agent === 'No Agent' ? 'bg-red-100' : '';
};
const getNoteStatusColor = status => {
  return status === 'published' ? 'green' : 'yellow';
};

export const getStatusColor = status => {
  let color = '';
  switch (status) {
    case 'completed':
      color = 'bg-green-100';
      break;
    case 'cancelled':
      color = 'bg-red-100';
      break;
    case 'noshow':
    case 'unresponsive':
      color = 'bg-purple-100';
      break;
    default:
      color = 'bg-yellow-100';
  }

  return color;
};

const columns = [
  { Header: 'Consult Email', accessor: 'email' },
  {
    Header: 'Kit ID',
    id: 'kit_id',
    accessor: row => (row.kit_id ? row.kit_id : 'NA'),
  },
  { Header: 'MA First Name', accessor: 'mainaccount.first_name' },
  { Header: 'MA Last Name', accessor: 'mainaccount.last_name' },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getStatusColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Scheduled',
    id: 'scheduled_dt',
    accessor: row =>
      row.scheduled_dt
        ? moment
            .unix(row.scheduled_dt)
            .tz('US/Central')
            .format('YYYY-MM-DDTHH:mm')
        : '',
  },
  {
    Header: 'Agent',
    accessor: row =>
      row.consult_agent?.email
        ? row.consult_agent?.email
        : row.agent
        ? row.agent
        : 'No Agent',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getAgentColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Completed',
    id: 'completed_dt',
    accessor: row =>
      !row.completed_dt && row.scheduled_dt < moment().format('YYYY-MM-DD')
        ? 'Missing'
        : row.completed_dt,
    Cell: ({ value }) => (
      <div>
        <span
          className={c('text-sm rounded-2xl p-2', getCompletedColor(value))}
        >
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Consult Note',
    id: 'consult_note_status',
    accessor: row => row.consult_note?.status,
    Cell: ({ value }) => (
      <div>
        <span
          className={c(
            `flex text-sm text-center rounded-2xl p-2 bg-${getNoteStatusColor(
              value,
            )}-100 mb-1 w-24`,
          )}
        >
          {value}
        </span>
      </div>
    ),
  },
];
export default function Consults() {
  return (
    <PageListView
      header='Consults'
      rightButton={{
        title: 'Schedule a consult',
        url: '/consults/new',
      }}
      tableProps={{
        type: 'consults',
        route: 'consults',
        columns: columns,
        filterType: 'status',
        filters: ['all', 'scheduled', 'no_show', 'completed', 'cancelled'],
        defaultFilter: 'scheduled',
        searchable: true,
      }}
    />
  );
}
