import moment from 'moment';

export const dateFormatRegex = new RegExp(
  '^(?:19[0-9][0-9]|[2-9][0-9][0-9][0-9])-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
);

export const checkValidDates = (obj, date_cols) => {
  for (let col of date_cols) {
    const dt = obj[col];
    if (dt && (!moment(dt).isValid() || !dateFormatRegex.test(dt))) {
      throw new Error(`Invalid ${col} date: ${dt}`);
    }
  }
};
