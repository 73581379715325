import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { consultStatuses } from '../../lib/constants';
import { formatDate } from '../../lib/utils/formatDate';

export default function Subscription () {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const getSubscription = async () => {
    setFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/subscriptions/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { subscription } = await res.json();
      setParams(subscription);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };
  const updateSubscription = async () => {
    setErrors([]);
    setUpdated(false);

    const updateData = {
      mainaccount_id: params.mainaccount_id,
    };

    const res = await fetch(`${API_BASE_PATH}/ops/admins/subscriptions/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      const { subscription } = await res.json();
      if (id === 'new') {
        history.push(`/subscriptions/${subscription.id}`);
      }
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };
  useEffect(() => {
    setRoute('subscriptions');
    if (id !== 'new') {
      getSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Subscription {id}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateSubscription()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Subscription {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this subscription:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Subscription information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Recharge Customer ID
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.customer_id}
                            <Link
                              to={{
                                pathname: `https://tinyhealth-sp.admin.rechargeapps.com/merchant/customers/${params.customer_id}`,
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='underline text-blue-600 pl-2'
                            >
                              View Recharge Customer
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Shopify Customer ID
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.shopify_customer_id}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Shopify Product ID
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.shopify_product_id}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Buyer Email
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.email}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            {params.mainaccount_id ? (
                              <NavLink
                                to={`/mainaccounts/${params.mainaccount_id}`}
                                className='flex-auto relative w-full m-0 py-2 text-sm hover:text-purple-300'
                              >
                                Main Account ID
                              </NavLink>
                            ) : (
                              'Main Account ID'
                            )}
                          </label>
                          <input
                            placeholder=''
                            value={params.mainaccount_id}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                mainaccount_id: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Status</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.status}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Quantity</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.quantity}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Next Delivery Date
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.date_next_charge_scheduled
                              ? formatDate(params.date_next_charge_scheduled)
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Order Interval Frequency
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.order_interval_frequency}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Order Interval Unit
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.order_interval_unit}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Cancelled Date
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.date_cancelled_at
                              ? formatDate(params.date_cancelled_at)
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Cancelled Reason
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.cancellation_reason}
                          </span>
                        </div>
                      </div>
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Subscription End Date
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.date_subscription_ends
                              ? formatDate(params.date_subscription_ends)
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
