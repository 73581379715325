import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';

import Logo from '../../assets/logo/logo.svg';

export default function Signup () {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [validating, setValidating] = useState(false);
  const [responseError, setResponseError] = useState();
  const [success, setSuccess] = useState(false);

  const submit = async e => {
    e.preventDefault();
    setResponseError(null);
    setValidating(true);
    const params = {
      firstName,
      lastName,
      email,
      password,
    };
    const res = await fetch(`${API_BASE_PATH}/ops/admins/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (res.status === 201) {
      setSuccess(true);
      setValidating(false);
    } else {
      const { error } = await res.json();
      setResponseError(
        error ? error : `Something went wrong, please try again.`,
      );
      setValidating(false);
    }
  };
  if (success) {
    return (
      <div className='flex flex-col m-0 p-4 border-0 h-full min-h-screen bg-grey-100'>
        <div className='flex flex-col p-2'>
          <div className='flex items-center'>
            <img src={Logo} className='h-12' alt='Tiny Ops' />
          </div>
        </div>
        <div className='max-w-lg grid gap-0 grid-flow-row mt-10 mb-0 mx-auto'>
          <div className='flex flex-col mb-4'>
            <h1 className='m-0 text-4xl font-serif'>{`You're almost there!`}</h1>
            <h2 className='mt-4 text-sm leading-5'>
              {`We sent an email to an admin to verify your email: `}
              <span className='text-sm text-green-300'>{email}</span>
            </h2>
          </div>
          <div className='flex flex-col mb-8'>
            <span className='text-sm m-0 mb-4'>
              This could take up to 24 hours.
            </span>
            <span className='text-sm m-0'>
              In the meantime, you can also message Donald on Slack to confirm
              your account.
            </span>
          </div>
          <span className='text-sm m-0'>
            Having trouble?{' '}
            <a
              href='mailto:donald@tinyhealth.com'
              className='text-sm cursor-pointer text-green-300'
            >
              Contact Donald
            </a>
            .
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className='flex flex-col m-0 p-4 border-0 h-full min-h-screen bg-grey-100'>
      <div className='flex flex-col p-2'>
        <div className='flex items-center'>
          <img src={Logo} className='h-12' alt='Tiny Ops' />
        </div>
      </div>
      <div className='max-w-lg grid gap-0 grid-flow-row mt-10 mb-0 mx-auto'>
        <div className='flex flex-col mb-4'>
          <h1 className='m-0 text-4xl font-serif'>{`Let's create an account`}</h1>
        </div>
        <form autoComplete='off'>
          <div className='grid gap-4 grid-cols-2 grid-flow-row'>
            <div className='flex flex-col'>
              <label htmlFor='First name' />
              <input
                aria-label='First name'
                type='text'
                placeholder='First name'
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setResponseError(null);
                }}
                className={
                  responseError
                    ? 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-red-300 transition-all duration-200 ease-in-out delay-75'
                    : 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                }
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='Last name' />
              <input
                aria-label='Last name'
                type='text'
                placeholder='Last name'
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                  setResponseError(null);
                }}
                className={
                  responseError
                    ? 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-red-300 transition-all duration-200 ease-in-out delay-75'
                    : 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                }
              />
            </div>
            <div className='flex flex-col col-span-full'>
              <label htmlFor='Work email' />
              <input
                aria-label='Work email'
                type='email'
                placeholder='Work email'
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setResponseError(null);
                }}
                className={
                  responseError
                    ? 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-red-300 transition-all duration-200 ease-in-out delay-75'
                    : 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                }
              />
            </div>
            <div className='flex flex-col col-span-full'>
              <label htmlFor='Password' />
              <input
                aria-label='Password'
                type='password'
                placeholder='Password (min 8 characters)'
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                  setResponseError(null);
                }}
                className={
                  responseError
                    ? 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-red-300 transition-all duration-200 ease-in-out delay-75'
                    : 'text-sm rounded-md leading-6 py-2 px-3 bg-white outline-none border border-solid border-grey-500 transition-all duration-200 ease-in-out delay-75'
                }
              />
            </div>
            {validating ? (
              <div className='col-span-full flex flex-col md:flex-row md:items-center'>
                <button
                  disabled
                  className='cursor-default bg-gray-800 text-white m-0 py-3 px-4 rounded-md leading-5 text-sm inline-block items-start text-center transition-all duration-200 ease-in-out delay-75'
                >
                  Continue
                </button>
                <span className='text-sm md:ml-6 mt-2 md:mt-0'>
                  Already registered?{' '}
                  <NavLink
                    to='/login'
                    className='text-sm underline text-green-300 hover:text-green-400'
                  >
                    Login
                  </NavLink>
                </span>
              </div>
            ) : (
              <div className='col-span-full flex flex-col md:flex-row md:items-center'>
                <button
                  onClick={e => submit(e)}
                  className='bg-green-300 text-white m-0 py-3 px-4 rounded-md leading-5 text-sm inline-block items-start text-center transition-all duration-200 ease-in-out delay-75 hover:bg-green-400'
                >
                  Continue
                </button>
                <span className='text-sm md:ml-6 mt-2 md:mt-0'>
                  Already registered?{' '}
                  <NavLink
                    to='/login'
                    className='text-sm underline text-green-300 hover:text-green-400'
                  >
                    Login
                  </NavLink>
                </span>
              </div>
            )}
            <span className='col-span-full mt-1 text-red-300 text-xs'>
              {responseError}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
