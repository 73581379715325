import { getSvgByName } from '../../helpers';

const ICON_SIZES = {
  xs: 12,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
};

export const Icon = ({
  name,
  SvgIcon = null,
  className = '',
  size = 'm',
  active = false,
}) => {
  let Svg = SvgIcon ? SvgIcon : getSvgByName(name);

  return (
    <span
      className={className}
      style={{
        display: 'inline-flex',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Svg
        className={`uikit ${active ? 'active' : ''}`}
        height={ICON_SIZES[size] ?? size}
        width={ICON_SIZES[size] ?? size}
      />
    </span>
  );
};
