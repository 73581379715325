import React from 'react';
import { NavLink } from 'react-router-dom';
import { VersionPill } from '../VersionPill/VersionPill';

export default function KitCard({ kit, latestSciVersion }) {
  return (
    <div className='first:mt-0 mt-4' key={kit.id}>
      <div className='flex-1 max-w-full break-all'>
        <div className='flex flex-col relative'>
          <NavLink
            to={`/kits/${kit.id}`}
            className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
          >
            <h6 className='font-bold text-base'>{kit.id}</h6>
            {(kit.ta_first_name || kit.ta_first_name) && (
              <span className='font-semibold text-sm mt-1'>
                {kit.ta_first_name} {kit.ta_last_name}
              </span>
            )}
            {kit.sample_date && (
              <p className='text-sm mt-1'>{kit.sample_date}</p>
            )}
            {(kit.sample_label || kit.sampling_type) && (
              <p className='text-sm'>
                {kit.sample_label}, {kit.sampling_type}
              </p>
            )}
            <div className='flex items-center mt-2'>
              {!kit.status ? null : kit.status?.indexOf('void') >= 0 ? (
                <span className='text-sm rounded-2xl p-2 bg-red-100 '>
                  {kit.status}
                </span>
              ) : kit.status === 'activated' ? (
                <span className='text-sm rounded-2xl p-2 bg-green-100 '>
                  {kit.status}
                </span>
              ) : kit.status === 'initialized' ? (
                <span className='text-sm rounded-2xl p-2 bg-purple-100 '>
                  {kit.status}
                </span>
              ) : (
                <span className='text-sm rounded-2xl p-2 bg-yellow-100 '>
                  {kit.status}
                </span>
              )}
            </div>
            <div className='flex items-center mt-2'>
              <VersionPill
                version={kit.sci_version}
                latestSciVersion={latestSciVersion}
              />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
