import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Age Group', accessor: 'age_group' },
  { Header: 'Age Low', accessor: 'age_low' },
  { Header: 'Age High', accessor: 'age_high' },
  { Header: 'Sampling Type', accessor: 'sampling_type' },
  { Header: 'Published', accessor: 'published' },
];

export default function KeyInsightsCategories () {
  return (
    <PageListView
      header='Key Insights Categories'
      tableProps={{
        type: 'keyInsightsCategory',
        route: 'keyInsightsCategory',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
