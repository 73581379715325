import React, { useState } from 'react';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import PageListView from '../../components/Page/PageListView';
import UnusedKit from '../../components/UnusedKit/UnusedKit';

const no_return_label = 'No return label';
const has_return_label = 'Has return label';

const columns = [
  { Header: 'Kit Id', accessor: 'id' },
  {
    Header: 'Tracking to lab (microbiome)',
    accessor: 'tracking_to_lab_microbiome',
  },
  {
    Header: 'Tracking to lab (stool chem)',
    accessor: 'tracking_to_lab_stoolchemistry',
  },
];

const UnusedKitTableRow = ({ row, row_index, filter, removeRow }) => {
  const [error, setError] = useState();
  const [params, setParams] = useState({});
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);

  if (filter !== no_return_label) return null;
  if (error) return <>{error}</>;

  return (
    <>
      <UnusedKit
        unused_kit={row.original}
        row_index={row_index}
        removeRow={removeRow}
      />
    </>
  );
};

export default function UnusedKits() {
  return (
    <PageListView
      header='Fulfillment: Kit Prep'
      tableProps={{
        type: 'unusedKits',
        route: 'unused-kits',
        columns: columns,
        filters: [no_return_label, has_return_label],
        defaultFilter: no_return_label,
        CustomRowComponent: UnusedKitTableRow,
        showCustomRowComponent: filter => filter === no_return_label,
        disabled: filter => filter === no_return_label,
        searchable: true,
      }}
    />
  );
}
