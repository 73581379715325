import React from 'react';
import Loader from 'react-spinners/HashLoader';

export default function TableLoading () {
  return (
    <table className='w-full min-w-full border-collapse'>
      <tbody>
        <tr>
          <td>
            <div className='my-10 mx-auto'>
              <div className='flex justify-center'>
                <Loader color='#009b87' size='75px' speedMultiplier='0.75' />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
