const PageHeader = ({ title }) => {
  return (
    <div className='flex-nowrap flex justify-between'>
      <div className='mt-0 flex-auto self-center'>
        <div>
          <h1 className='text-2xl break-word font-serif font-semibold m-0'>
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
