import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { API_BASE_PATH } from '../config/api';
import useStore from '../stores';

import BackArrow from '../assets/iconography/back_arrow.svg';

export default function InitializeKit () {
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [kits, setKits] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [orderID, setOrderID] = useState();
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const initializeKit = async () => {
    setFetching(true);
    setErrors([]);
    setUpdated(false);
    if (!orderID) {
      setErrors(['Please enter an order ID.']);
      setFetching(false);
    } else if (orderID.length < 10) {
      setErrors([
        `That doesn't look like an order ID, maybe it's an order number? Make sure it's the order ID`,
      ]);
      setFetching(false);
    } else {
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/kits/${orderID}/initialize`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 201) {
        const { kits } = await res.json();
        if (kits.length < 1) {
          setKits(['No missing kits needed to be initialized']);
        } else {
          setKits(kits);
        }
        setUpdated(true);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
  };
  useEffect(() => {
    setRoute('kits');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='flex-none max-w-full mr-2 lg:mr-6'>
              <NavLink
                to='/kits'
                className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
              >
                <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                  <div className='m-0'>
                    <img
                      src={BackArrow}
                      className='relative block h-5 w-5'
                      alt='Tiny Ops'
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Initialize Kit
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => initializeKit()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 cursor-pointer'
                >
                  Initialize
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Kits successfully initialized:
                    <div className='flex flex-col'>
                      {kits.map((kit, kit_index) => {
                        return (
                          <span
                            key={kit_index}
                            className='text-sm mr-2 mt-1 font-semibold'
                          >
                            {kit}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with these kits:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-2 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          {`Enter the order ID, and we'll create missing kits`}
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Order ID</label>
                          <input
                            placeholder='2325'
                            onChange={e => setOrderID(e.target.value)}
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
