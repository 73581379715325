import c from 'classnames';
import PageListView from '../../components/Page/PageListView';
import { formatDate } from '../../lib/utils/formatDate';
import moment from 'moment';

export const getKitStatusColor = kitStatus => {
  let color = '';
  switch (kitStatus) {
    case 'activated':
    case 'done':
      color = 'bg-green-100';
      break;
    case kitStatus?.indexOf('void') >= 0:
    case 'cancelled':
      color = 'bg-red-100';
      break;
    case 'initialized':
      color = 'bg-purple-100';
      break;
    default:
      color = 'bg-yellow-100';
  }
  return color;
};

const getSurveyStatusColor = surveyStatus => {
  if (surveyStatus === 'complete') {
    return 'bg-green-100';
  }

  return 'bg-red-100';
};

const columns = filter => {
  if (filter === 'in_transit_to_customer') {
    return [
      {
        Header: 'Kit ID',
        accessor: 'id',
      },
      { Header: 'Buyer Email', accessor: 'buyer_email' },
      {
        Header: 'Kit Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div>
            <span
              className={c('text-sm rounded-2xl p-2', getKitStatusColor(value))}
            >
              {value}
            </span>
          </div>
        ),
      },
      { Header: 'Order Number', accessor: 'order_number' },
      { Header: 'Item Name', accessor: 'item_name' },
      {
        Header: 'Purchase Price',
        accessor: 'purchase_price',
        Cell: ({ value }) => (
          <div>
            {value ? (
              <span className='text-sm'>${value}</span>
            ) : (
              <span className='text-sm text-purple-300'>FREE</span>
            )}
          </div>
        ),
      },
      {
        Header: 'In Transit Date',
        id: 'date_in_transit_to_customer',
        accessor: row =>
          row.date_in_transit_to_customer
            ? formatDate(row.date_in_transit_to_customer)
            : 'None',
      },
      {
        Header: 'Date Purchased',
        id: 'date_purchased',
        accessor: row =>
          row.date_purchased ? formatDate(row.date_purchased) : 'None',
      },
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: row => formatDate(row.created_at),
      },
    ];
  }

  if (filter === 'activated') {
    return [
      {
        Header: 'Kit ID',
        accessor: 'id',
      },
      {
        Header: 'Email',
        accessor: row => row.mainaccount?.email,
        disable_sort: true,
      },
      { Header: 'Lab Partner', accessor: 'lab_partner' },
      {
        Header: 'Sample Date',
        id: 'sample_date',
        accessor: row =>
          row.sample_date ? formatDate(row.sample_date) : 'None',
      },
      { Header: 'Sample Label', accessor: 'sample_label' },
      { Header: 'Resample', accessor: 'is_resample' },
      { Header: 'Blocked', accessor: 'blocked_by_user' },
      { Header: 'Buyer Country', accessor: 'buyer_country_code' },
    ];
  }

  if (filter === 'in_transit_to_lab') {
    return [
      {
        Header: 'Kit ID',
        accessor: 'id',
      },
      { Header: 'Lab Partner', accessor: 'lab_partner' },
      {
        Header: 'Sample Date',
        id: 'sample_date',
        accessor: row =>
          row.sample_date ? formatDate(row.sample_date) : 'None',
      },
      { Header: 'Sample Label', accessor: 'sample_label' },
      {
        Header: 'In Transit Date',
        id: 'date_in_transit_to_lab',
        accessor: row =>
          row.date_in_transit_to_lab
            ? formatDate(row.date_in_transit_to_lab)
            : 'None',
      },

      { Header: 'Resample', accessor: 'is_resample' },
      { Header: 'Blocked', accessor: 'blocked_by_user' },
      { Header: 'Buyer Country', accessor: 'buyer_country_code' },
    ];
  }

  if (filter === 'delivered_to_lab') {
    return [
      {
        Header: 'Kit ID',
        accessor: 'id',
      },
      { Header: 'Lab Partner', accessor: 'lab_partner' },
      {
        Header: 'Sample Date',
        id: 'sample_date',
        accessor: row =>
          row.sample_date ? formatDate(row.sample_date) : 'None',
      },
      { Header: 'Sample Label', accessor: 'sample_label' },
      {
        Header: 'Delivered Date',
        id: 'date_delivered_to_lab',
        accessor: row =>
          row.date_delivered_to_lab
            ? formatDate(row.date_delivered_to_lab)
            : 'None',
      },
      {
        Header: 'Weeks at Lab',
        id: 'weeks_at_lab',
        disable_sort: true,
        accessor: row =>
          Number(
            moment().diff(moment(row.date_delivered_to_lab), 'weeks', true),
          ).toFixed(1),
      },
      { Header: 'Resample', accessor: 'is_resample' },
      {
        Header: 'Membership Plan',
        accessor: row => row.mainaccount?.membership_plan,
      },
      { Header: 'Discount Code', accessor: 'discount_code' },
    ];
  }

  if (filter === 'results_ready') {
    return [
      {
        Header: 'Kit ID',
        accessor: 'id',
      },
      { Header: 'Buyer Email', accessor: 'buyer_email' },
      {
        Header: 'Sample Date',
        id: 'sample_date',
        accessor: row =>
          row.sample_date ? formatDate(row.sample_date) : 'None',
      },
      { Header: 'Sample Label', accessor: 'sample_label' },
      {
        Header: 'Results Ready Date',
        id: 'date_results_ready',
        accessor: row =>
          row.date_results_ready ? formatDate(row.date_results_ready) : 'None',
      },
      {
        Header: 'Surveys',
        disable_sort: true,
        accessor: row => {
          return row.survey_status?.pending.length === 0
            ? 'complete'
            : 'incomplete';
        },
        Cell: ({ value }) => (
          <div>
            <span
              className={c(
                'text-sm rounded-2xl p-2',
                getSurveyStatusColor(value),
              )}
            >
              {value}
            </span>
          </div>
        ),
      },
      { Header: 'Resample', accessor: 'is_resample' },
      {
        Header: 'Edited Note Preview',
        disable_sort: true,
        accessor: row => row.note?.edited_value?.substring(0, 20),
      },
    ];
  }

  return [
    {
      Header: 'Kit ID',
      accessor: 'id',
    },
    { Header: 'Buyer Email', accessor: 'buyer_email' },
    {
      Header: 'Kit Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <div>
          <span
            className={c('text-sm rounded-2xl p-2', getKitStatusColor(value))}
          >
            {value}
          </span>
        </div>
      ),
    },
    { Header: 'Order Number', accessor: 'order_number' },
    { Header: 'Item Name', accessor: 'item_name' },
    {
      Header: 'Purchase Price',
      accessor: 'purchase_price',
      Cell: ({ value }) => (
        <div>
          {value ? (
            <span className='text-sm'>${value}</span>
          ) : (
            <span className='text-sm text-purple-300'>FREE</span>
          )}
        </div>
      ),
    },
    {
      Header: 'Sample Date',
      id: 'sample_date',
      accessor: row => (row.sample_date ? formatDate(row.sample_date) : 'None'),
    },
    {
      Header: 'Date Purchased',
      id: 'date_purchased',
      accessor: row =>
        row.date_purchased ? formatDate(row.date_purchased) : 'None',
    },
    {
      Header: 'Created At',
      id: 'created_at',
      accessor: row => formatDate(row.created_at),
    },
  ];
};
export default function Kits() {
  return (
    <PageListView
      header='Kits'
      rightButton={{
        title: 'Initialize a kit',
        url: '/kits/initialize',
      }}
      tableProps={{
        type: 'kits',
        route: 'kits',
        columns: columns,
        filterType: 'status',
        filters: [
          'all',
          'initialized',
          'in_transit_to_customer',
          'delivered_to_customer',
          'activated',
          'in_transit_to_lab',
          'delivered_to_lab',
          'sequences_delivered',
          'results_ready',
          'expert_review_complete',
          'void',
        ],
        defaultFilter: 'all',
        searchable: true,
      }}
    />
  );
}
