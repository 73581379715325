import React from 'react';

export default function FieldValue ({
  title,
  subtitle,
  value,
}: {
  title: string;
  subtitle?: string;
  value: string | number | React.ReactNode;
}) {
  return (
    <div className='flex-1 max-w-full mt-4'>
      <div className='flex flex-col relative'>
        <label className={`${subtitle ? '' : 'mb-1'} p-0 text-sm`}>
          {title}
        </label>
        {subtitle && (
          <label className='mb-1 p-0 text-xs opacity-60'>{subtitle}</label>
        )}
        <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
          {value}
        </div>
      </div>
    </div>
  );
}
