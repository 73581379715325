import PageListView from '../../components/Page/PageListView';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'lookup_code',
    accessor: 'lookup_code',
  },
  {
    Header: 'question_text',
    accessor: 'question_text',
  },
  {
    Header: 'sex',
    accessor: 'sex',
  },
  {
    Header: 'age_low',
    accessor: 'age_low',
  },
  {
    Header: 'age_high',
    accessor: 'age_high',
  },
  {
    Header: 'is_sample',
    accessor: row => (row.is_sample === 1 ? 'Yes' : 'No'),
  },
  {
    Header: 'is_resample',
    accessor: row => (row.is_resample === 1 ? 'Yes' : 'No'),
  },
  {
    Header: 'can_change_between_samples',
    accessor: row => (row.can_change_between_samples === 1 ? 'Yes' : 'No'),
  },
];

export default function ProfileQuestions() {
  return (
    <PageListView
      header='Profile Questions'
      tableProps={{
        type: 'profileQuestions',
        route: 'profile-questions',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Lookup Code',
            filter: 'lookup_code',
          },
          {
            label: 'Question Text',
            filter: 'question_text',
          },
        ],
      }}
    />
  );
}
