import React, { useState, useEffect } from 'react';

import { API_BASE_PATH } from '../../config/api';
import { updateDateParam, checkValidDates } from '../../helpers';
import useStore from '../../stores';

export default function PartnerRegister () {
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({
    email: '',
    first_name: '',
    last_name: '',
    tiny_first_name: '',
    tiny_last_name: '',
    tiny_gender: '',
    tiny_birthdate: '',
    kit_id: '',
    sample_date: '',
    sampling_type: null,
  });
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [registeredUser, setRegisteredUser] = useState({
    mainAccount: null,
    tinyAccount: null,
    kit: null,
  });
  const registerKit = async () => {
    setErrors([]);
    setFetching(true);
    setUpdated(false);

    try {
      checkValidDates(params, ['tiny_birthdate', 'sample_date']);
    } catch (err) {
      setErrors([err.message]);
      setFetching(false);
      return;
    }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/partners/register`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    setFetching(false);
    if (res.status === 200) {
      const { mainAccount, tinyAccount, kit } = await res.json();
      setRegisteredUser({ mainAccount, tinyAccount, kit });
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };
  useEffect(() => {
    setRoute('partner_register');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let canSubmit = false;
  if (
    params.email?.length > 0 &&
    params.first_name?.length > 0 &&
    params.last_name?.length > 0 &&
    params.tiny_first_name?.length > 0 &&
    params.tiny_last_name?.length > 0 &&
    params.tiny_gender?.length > 0 &&
    params.tiny_birthdate?.length > 0 &&
    params.kit_id?.length > 0 &&
    params.sample_date?.length > 0
  ) {
    canSubmit = true;
  }

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Register Partner Kit
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => registerKit()}
                  disabled={fetching || !canSubmit}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Register
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Registered {registeredUser.kit?.id} to{' '}
                    {registeredUser.mainAccount?.email} (
                    {registeredUser.mainAccount?.id}) successfully
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    :
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Main Account information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Email</label>
                          <input
                            placeholder=''
                            value={params.email}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                email: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>First Name</label>
                          <input
                            placeholder=''
                            value={params.first_name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                first_name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Last Name</label>
                          <input
                            placeholder=''
                            value={params.last_name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                last_name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Tiny Account information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Baby First Name
                          </label>
                          <input
                            placeholder=''
                            value={params.tiny_first_name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                tiny_first_name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Baby Last Name
                          </label>
                          <input
                            placeholder=''
                            value={params.tiny_last_name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                tiny_last_name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Baby Gender
                          </label>
                          <select
                            value={params.tiny_gender}
                            name='Baby Gender'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                tiny_gender: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option value='' disabled>
                              -- Choose a gender --
                            </option>
                            <option value='M'>Male</option>
                            <option value='F'>Female</option>
                          </select>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Baby Birthdate
                          </label>
                          <input
                            placeholder='Must be in "YYYY-MM-DD" format'
                            value={params.tiny_birthdate}
                            onChange={e =>
                              updateDateParam(
                                e.target.value,
                                'tiny_birthdate',
                                setParams,
                              )
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Kit information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Kit ID</label>
                          <input
                            placeholder=''
                            value={params.kit_id}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                kit_id: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Sample date
                          </label>
                          <input
                            placeholder='Must be in "YYYY-MM-DD" format'
                            value={params.sample_date}
                            onChange={e =>
                              updateDateParam(
                                e.target.value,
                                'sample_date',
                                setParams,
                              )
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Sampling Type
                          </label>
                          <select
                            value={params.sampling_type}
                            name='Sampling Type'
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                sampling_type: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          >
                            <option disabled selected>
                              -- Choose a sampling type --
                            </option>
                            <option value='stool'>Stool</option>
                            <option value='vaginal'>Vaginal</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
