import c from 'classnames';
import PageListView from '../../components/Page/PageListView';
import { formatDate } from '../../lib/utils/formatDate';

export const getStatusColor = status => {
  let color = '';
  switch (status) {
    case 'active':
      color = 'bg-green-100';
      break;
    case 'cancelled':
    case 'deleted':
    case 'expired':
      color = 'bg-red-100';
      break;
    default:
      color = 'bg-yellow-100';
  }

  return color;
};

const columns = filter => {
  const list = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Main Account ID', accessor: 'mainaccount_id' },
    { Header: 'Email', accessor: 'email' },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <div>
          <span className={c('text-sm rounded-2xl p-2', getStatusColor(value))}>
            {value}
          </span>
        </div>
      ),
    },
    { Header: 'Quantity', accessor: 'quantity' },
    {
      Header: 'Next Delivery Date',
      id: 'date_next_charge_scheduled',
      accessor: row =>
        row.date_next_charge_scheduled
          ? formatDate(row.date_next_charge_scheduled)
          : '',
    },
    { Header: 'Frequency', accessor: 'order_interval_frequency' },
    { Header: 'Unit', accessor: 'order_interval_unit' },
  ];

  if (filter !== 'active') {
    list.push({
      Header: 'Cancelled Date',
      id: 'date_cancelled_at',
      accessor: row =>
        row.date_cancelled_at ? formatDate(row.date_cancelled_at) : '',
    });
  }

  return list;
};

export default function Subscriptions () {
  return (
    <PageListView
      header='Subscriptions'
      tableProps={{
        type: 'subscriptions',
        route: 'subscriptions',
        columns: columns,
        filterType: 'status',
        filters: ['all', 'active', 'cancelled', 'expired', 'deleted'],
        defaultFilter: 'active',
        searchable: true,
      }}
    />
  );
}
