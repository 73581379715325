import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

export default function ProfileQuestion() {
  const route = 'profile-question';
  const history = useHistory();

  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);

  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'profileQuestion');

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      question_text: params.question_text,
      answer_type: params.answer_type,
      sex: params.sex,
      age_low: params.age_low,
      age_high: params.age_high,
      is_sample: params.is_sample,
      is_resample: params.is_resample,
      can_change_between_samples: params.can_change_between_samples,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  return (
    <PageDetailView
      header={`Profile Question ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Question Lookup Code</label>
              {params.lookup_code}
            </div>
          </div>

          <TextInput
            title='Question Text'
            placeholder='question...?'
            value={params.question_text}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                question_text: e.target.value,
              }))
            }
          />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>
                Answer Options (Separated by |)
              </label>
              {params.answer_options}
            </div>
          </div>

          <SelectInput
            title='Sex'
            options={[
              { value: '', title: '-- Choose --', disabled: true },
              { value: 'all', title: 'All' },
              { value: 'female_other', title: 'Female and Other' },
              { value: 'male', title: 'Male' },
            ]}
            value={params.answer_type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                answer_type: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age Low'
            placeholder='Age Range Low'
            value={params.age_low}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_low: e.target.value,
              }))
            }
          />

          <TextInput
            type='number'
            title='Age High'
            placeholder='Age Range High'
            value={params.age_high}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                age_high: e.target.value,
              }))
            }
          />

          <SelectInput
            title='Answer Type'
            options={[
              { value: '', title: '-- Choose --', disabled: true },
              { value: 'boolean', title: 'Yes/No' },
              { value: 'array', title: 'Multi-option' },
              { value: 'string', title: 'One option' },
              { value: 'number', title: 'Number' },
              { value: 'percent', title: 'Percent' },
            ]}
            value={params.answer_type ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                answer_type: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Sample Related Question Only'
            checked={params.is_sample}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                is_sample: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Resample Only'
            checked={params.is_resample}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                is_resample: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Can change between samples'
            checked={params.can_change_between_samples}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                can_change_between_samples: Number(e.target.checked),
              }))
            }
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
