import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/iconography/back_arrow.svg';

interface PageDetailViewProps {
  header: string;
  fetching: boolean;
  updated: boolean;
  errors: string[];
  backUrl?: string;
  fnSave: () => void;
  children?: React.ReactNode;
}

const PageDetailView = ({
  header,
  fetching,
  updated,
  errors,
  backUrl,
  fnSave,
  children,
}: PageDetailViewProps) => {
  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            {backUrl && (
              <div className='flex-none max-w-full mr-2 lg:mr-6'>
                <NavLink
                  to={`/${backUrl}`}
                  className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
                >
                  <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                    <div className='m-0'>
                      <BackArrow className='relative block h-5 w-5' />
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                {header}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              {fnSave && (
                <div className='ml-2 lg:ml-4 mt-0 flex'>
                  <button
                    onClick={() => fnSave()}
                    disabled={fetching}
                    className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    {header} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}
                    {': '}:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageDetailView;
