import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Sampling Type', accessor: 'sampling_type' },
  { Header: 'Age Group', accessor: 'age_group' },
  { Header: 'Age low', accessor: 'age_low' },
  { Header: 'Age high', accessor: 'age_high' },
  { Header: 'Section', accessor: 'section' },
  { Header: 'Priority', accessor: 'priority' },
  { Header: 'Published', accessor: 'published' },
];

export default function NoteSummary() {
  return (
    <PageListView
      header='Notes Summary'
      rightButton={{
        title: 'Create New',
        url: '/noteSummary/new',
      }}
      tableProps={{
        type: 'noteSummary',
        route: 'noteSummary',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Action Card Trigger',
            filter: 'action_card_trigger',
          },
          {
            label: 'Age Group',
            filter: 'age_group',
          },
          {
            label: 'Flag Category',
            filter: 'flag_category',
          },
          {
            label: 'Flag Insight',
            filter: 'flag_insight',
          },
          {
            label: 'Published',
            filter: 'published',
          },
          {
            label: 'Sampling Type',
            filter: 'sampling_type',
          },
          {
            label: 'Section',
            filter: 'section',
          },
          {
            label: 'Sub Section',
            filter: 'sub_section',
          },
          {
            label: 'Summary Text',
            filter: 'summary_text',
          },
          {
            label: 'Title',
            filter: 'title',
          },
        ],
      }}
    />
  );
}
