import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Taxonomy Category', accessor: 'taxonomy_category' },
  { Header: 'Taxonomy Name', accessor: 'taxonomy_name' },
  { Header: 'Category Gut', accessor: 'category_gut' },
  { Header: 'Category Vaginal', accessor: 'category_vaginal' },
];

export default function ReportTaxaCategories() {
  return (
    <PageListView
      header='Report Taxa Categories'
      tableProps={{
        type: 'reportTaxaCategories',
        route: 'reportTaxaCategories',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Taxonomy Category',
            filter: 'taxonomy_category',
          },
          {
            label: 'Taxonomy Name',
            filter: 'taxonomy_name',
          },
          {
            label: 'Category Gut',
            filter: 'category_gut',
          },
          {
            label: 'Category Vaginal',
            filter: 'category_vaginal',
          },
        ],
      }}
    />
  );
}
