import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Slug',
    accessor: 'slug',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export default function Partners() {
  return (
    <PageListView
      header='Partners'
      rightButton={{
        title: 'Create New',
        url: '/partners/new',
      }}
      tableProps={{
        type: 'partners',
        route: 'partners',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Name',
            filter: 'name',
          },
          {
            label: 'Slug',
            filter: 'slug',
          },
        ],
      }}
    />
  );
}
